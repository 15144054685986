import React from "react";
import { useTranslation } from "react-i18next";
import { Card, Row } from "antd";
import { dataConnectionLogos } from "../Helpers";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const servicesData = [
  {
    from: "Scoro",
    to: "Merit",
    connections: [
      { title: "ScoroInvoicesToMerit", price: "25" },
      { title: "ScoroBillsToMerit", price: "25" },
      { title: "ScoroSendCalendarSMS", price: "25", additional: "100" },
      { title: "ScoroContactsToMeritVendor", price: "14" },
      { title: "ScoroContactsToMeritClient", price: "14" },
      { title: "ScoroProjectsToMerit", price: "14" },
    ],
  },
  {
    from: "Merit",
    to: "Scoro",
    connections: [
      { title: "MeritPurchaseOrdersToScoro", price: "25" },
      { title: "MeritDepotsToScoro", price: "20" },
      { title: "MeritProjectsToScoro", price: "14" },
      { title: "MeritInvoiceReceiptsToScoro", price: "14" },
    ],
  },
  {
    from: "Woocommerce",
    to: "Merit",
    connections: [{ title: "WoocommerceOrdersToMerit", price: "10" }],
  },
];

const ServicePage = () => {
  const { t } = useTranslation();
  return (
    <div className="dashboard-services-info">
      <h2>{t("Services")}</h2>
      <Row className="data-connections-row">
        {servicesData.map((service) => (
          <Card key={`${service.from}-${service.to}`} className="price-card">
            <div style={{ display: "flex" }}>
              <img src={dataConnectionLogos[service.from]} alt={service.from} />
              <FontAwesomeIcon
                icon={faArrowRightLong}
                className="right-arrow-services"
              />
              <img src={dataConnectionLogos[service.to]} alt={service.to} />
            </div>
            {service.connections.map((connection) => (
              <React.Fragment key={connection.title}>
                <h3>{t(connection.title)}</h3>
                <h5>
                  {connection.price} {t("€/month")}
                  {connection.additional &&
                    ` (${t("limit")} ${connection.additional})`}
                </h5>
              </React.Fragment>
            ))}
          </Card>
        ))}
      </Row>
      <h4>{t("VAT included in the price")}</h4>
    </div>
  );
};

export default ServicePage;
