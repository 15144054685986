import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MyServicePage from "./pages/MyService";
import "./css/styles.css";
import ServiceDetails from "./pages/ServiceDetails";
import CompanyDetails from "./pages/CompanyDetails";
import CompanyAdd from "./pages/CompanyAdd";
import "./services/i18n";
import DataConnectionsPage from "./pages/DataConnections";
import DataConnectionDetails from "./pages/DataConnectionDetails";
import DataConnectionAddPage from "./pages/DataConnectionAdd";
import FeedbackPage from "./pages/Feedback";
import FeedbackDetails from "./pages/FeedbackDetails";
import NotFoundPage from "./pages/NotFoundPage";
import HelpPage from "./pages/Help";
import FeedbackRedirect from "./FeedbackRedirect";
import AuthenticatedBaseLayout from "./components/authenticatedBaseLayout";
import UnauthenticatedBaseLayout from "./components/unauthenticatedBaseLayout";
import PasswordReset from "./forms/PasswordReset";
import Login from "./forms/Login";
import Register from "./forms/Register";
import PasswordResetConfirm from "./forms/PasswordResetConfirm";
import axios from "axios";
import ServicePage from "./pages/Service";
import MyServiceAdd from "./pages/MyServiceAdd";
import ActivateAccount from "./forms/ActivateAccount";
import HomePage from "./pages/HomePage";

axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<HomePage />} />

      <Route element={<AuthenticatedBaseLayout />}>
        <Route path="services" element={<ServicePage />} />
        <Route path="services/my-services" element={<MyServicePage />} />
        <Route
          path="/services/:serviceId/edit"
          element={<ServiceDetails editMode={true} />}
        />
        <Route
          path="/services/:serviceId"
          element={<ServiceDetails editMode={false} />}
        />
        <Route path="services/my-services/add" element={<MyServiceAdd />} />
        <Route
          path="company/edit"
          element={<CompanyDetails editMode={true} />}
        />
        <Route path="company/add" element={<CompanyAdd />} />
        <Route path="company" element={<CompanyDetails editMode={false} />} />
        <Route path="/data-connections" element={<DataConnectionsPage />} />
        <Route
          path="/data-connections/:dataConnectionId"
          element={<DataConnectionDetails />}
        />
        <Route
          path="/data-connections/add"
          element={<DataConnectionAddPage />}
        />
        <Route path="feedback" element={<FeedbackRedirect />} />
        <Route path="/feedback/:activeTab" element={<FeedbackPage />} />
        <Route
          path="/feedback/topic/:feedbackId"
          element={<FeedbackDetails />}
        />
        <Route path="/help" element={<HelpPage />} />
      </Route>

      <Route element={<UnauthenticatedBaseLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="confirm/:token" element={<ActivateAccount />} />
        <Route path="register" element={<Register />} />
        <Route path="register/:registrationToken" element={<Register />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route
          path="/password-reset-confirm/:resetToken"
          element={<PasswordResetConfirm />}
        />
      </Route>

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  </BrowserRouter>
);

export default App;
