import React from "react";
import { useTranslation } from "react-i18next";

type LanguageIconComponentProps = {
  lng: string;
  name: string;
};

const LanguageButton: React.FC<LanguageIconComponentProps> = ({
  lng,
  name,
}) => {
  const { i18n } = useTranslation();
  return (
    <a
      href="#top"
      style={{
        background: "none",
        border: "none",
        padding: 0,
        font: "inherit",
        cursor: "pointer",
        fontWeight: "normal",
      }}
      onClick={() => {
        i18n.changeLanguage(lng);
      }}
    >
      {name}
    </a>
  );
};

export default LanguageButton;
