import React from "react";
import { Form, Input, message, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";
import * as apiService from "../services/apiServices";
import { useTranslation } from "react-i18next";
import { displayError } from "../Helpers";
import { SubmitButton } from "../components/styledComponents";

const { Text, Title } = Typography;

const NormalLoginForm = () => {
  const { t } = useTranslation();
  const onFinish = async (values: any) => {
    try {
      await apiService.resetPassword(values.email);
      message.success(t("Check your inbox"));
    } catch (error) {
      message.error(t(displayError(error, "Error resetting password")));
    }
  };
  return (
    <Form
      name="normal_login"
      size="large"
      className="loginForm"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
    >
      <Title>{t("Forgot password?")}</Title>
      <Text type="secondary">
        {t("Enter your email to reset the password")}
      </Text>
      <Form.Item
        style={{ marginTop: 30 }}
        name="email"
        rules={[
          {
            required: true,
            message: t("Please enter your email"),
          },
        ]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Email"
          type="email"
          style={{ height: "50px" }}
        />
      </Form.Item>

      <Form.Item>
        <SubmitButton>{t("Reset password")}</SubmitButton>
      </Form.Item>
    </Form>
  );
};

export default NormalLoginForm;
