import { Card, Col } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as apiService from "../services/apiServices";
import { DataConnection } from "../types";
import DataConnectionForm from "../forms/DataConnection";
import useFetchData from "../hooks/useFetchData";
import AuthenticatedBaseLayout from "../components/authenticatedBaseLayout";
import { useTranslation } from "react-i18next";
import { processError } from "../Helpers";
import { HelpQuestionCircle } from "../components/styledComponents";

const DataConnectionDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dataConnectionId } = useParams();
  const [dataConnection, setDataConnection] = useState<DataConnection>();
  const [dataConnections, error] = useFetchData(
    apiService.getUserDataConnections,
    { id: dataConnectionId },
  );

  useEffect(() => {
    if (dataConnections && Object.keys(dataConnections).length > 0) {
      setDataConnection(dataConnections[0]);
    }
  }, [dataConnections]);

  if (error || !dataConnectionId) {
    navigate(processError(error));
  }
  if (!dataConnection) return <AuthenticatedBaseLayout />;

  return (
    <Col xxl={12} xl={18} lg={24} md={24}>
      <Card>
        <h2>
          {dataConnection.name}

          <HelpQuestionCircle
            text={t(
              "Application programming interface authentication requires specific parameters that may vary depending on the interface.",
            )}
          />
        </h2>
        <DataConnectionForm dataConnection={dataConnection} />
      </Card>
    </Col>
  );
};

export default DataConnectionDetails;
