import React from "react";

type IconComponentProps = {
  icon: string;
};

const LanguageIcon: React.FC<IconComponentProps> = ({ icon }) => {
  return (
    <div
      className="ant-menu-item-icon"
      style={{
        width: "20px",
        height: "20px",
        overflow: "hidden",
        borderRadius: "50%",
      }}
    >
      <img
        src={icon}
        alt="icon"
        style={{ objectFit: "cover", height: "100%", width: "100%" }}
      />
    </div>
  );
};

export default LanguageIcon;
