import React, { useEffect, useState } from "react";
import { ServiceMainInfoProps } from "../types";
import { Button, Divider, message } from "antd";
import { ServiceStatus } from "./serviceCard";
import SwitchService from "./switchService";
import { Cron } from "react-js-cron";
import {
  adjustCronValue,
  displayError,
  estonianLocale,
  getNextTriggerTime,
} from "../Helpers";
import * as apiService from "../services/apiServices";
import { useTranslation } from "react-i18next";
import { HelpQuestionCircle } from "./styledComponents";

const ServiceMainInfo: React.FC<ServiceMainInfoProps> = ({
  service,
  setService,
}) => {
  const [cronValue, setCronValue] = useState("0 * * * *");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!service.cron) {
      return;
    }
    setCronValue(service.cron);
  }, [service]);

  const updateCron = () => {
    const fetchData = async () => {
      try {
        await apiService.updateCustomerService(
          service.id,
          service.settings,
          service.enabled,
          adjustCronValue(cronValue),
        );
        message.success(t("Schedule changed"));
      } catch (error) {
        message.error(t(displayError(error, "Error updating schedule")));
      }
    };
    fetchData();
  };

  return (
    <div>
      <h2>{t(service.service_name)}</h2>
      <Divider />
      <ServiceStatus
        status={service.status}
        service_id={service.id}
        detailed={true}
      ></ServiceStatus>
      <SwitchService service={service} setService={setService}></SwitchService>
      {service.enabled ? (
        <div>
          <Divider />
          <h2>
            {t("Service synchronization schedule")}{" "}
            <HelpQuestionCircle
              text={t(
                "The interval from which the service starts itself(according to company's timezone).",
              )}
            />
          </h2>
          <Cron
            value={cronValue}
            clearButton={false}
            setValue={setCronValue}
            locale={i18n.language === "ee" ? estonianLocale : undefined}
            allowedDropdowns={[
              "period",
              "months",
              "month-days",
              "week-days",
              "hours",
            ]}
            allowedPeriods={["year", "month", "week", "day"]}
            className="cron"
          />

          <Button size="large" onClick={updateCron}>
            {t("Update")}
          </Button>

          <h3>{t("Next run")}</h3>
          <span>{service.cron ? getNextTriggerTime(service.cron) : ""}</span>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default ServiceMainInfo;
