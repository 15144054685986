import React, { useEffect, useState } from "react";
import {
  Button,
  ConfigProvider,
  Drawer,
  Layout,
  Menu,
  MenuProps,
  message,
  Modal,
} from "antd";
import {
  ApiOutlined,
  BulbOutlined,
  ControlOutlined,
  DesktopOutlined,
  MenuOutlined,
  PlusOutlined,
  QuestionOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import EstoniaIcon from "../../images/ee.svg";
import UKIcon from "../../images/gb.svg";
import useFetchData from "../../hooks/useFetchData";
import * as apiService from "../../services/apiServices";
import { Customer, MenuLabels } from "../../types";
import { useNavigate } from "react-router-dom";
import { displayError, getCurrentCustomer } from "../../Helpers";
import LanguageIcon from "./languageIcon";
import LanguageButton from "./languageButton";
import { useTranslation } from "react-i18next";
import { DeleteButton } from "../styledComponents";
import Cookies from "js-cookie";

const { Sider } = Layout;
type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group",
  disabled?: false,
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
    disabled,
  } as MenuItem;
}

const LogoutButton = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLogout = async () => {
    Modal.confirm({
      title: t("Are you sure you want to log out?"),
      onOk: async () => {
        try {
          await apiService.logout();
          localStorage.clear();
          Cookies.remove("csrftoken");
          message.success(t("Logged out"));
          navigate("/login");
        } catch (error) {
          message.error(t(displayError(error, "Error logging out")));
        }
      },
      onCancel() {},
    });
  };

  return (
    <div style={{ marginLeft: "2%", marginRight: "2%" }}>
      <DeleteButton onClick={handleLogout} style={{}} type="default" danger>
        {t("Log out")}
      </DeleteButton>
    </div>
  );
};

const createMenuItems = (labels: MenuLabels): MenuItem[] => {
  const items: MenuItem[] = [
    getItem(
      labels.menuLabel,
      "grp1",
      null,
      [
        getItem(
          <a href="/services">{labels.servicesLabel}</a>,
          "services",
          <DesktopOutlined />,
          [
            getItem(
              <a href="/services/my-services">{labels.myServicesLabel}</a>,
              "myservices",
              <ApiOutlined />,
            ),
          ],
        ),
        getItem(
          <a href="/data-connections">{labels.dataConnectionsLabel}</a>,
          "data-connections",
          <ControlOutlined />,
        ),
        getItem(
          <a href="/company">{labels.companyLabel}</a>,
          "company",
          <TeamOutlined />,
        ),
        getItem(
          <a href="/feedback/new">{labels.feedbackLabel}</a>,
          "feedback",
          <BulbOutlined />,
        ),
        getItem(
          <a href="/help">{labels.helpLabel}</a>,
          "help",
          <QuestionOutlined />,
        ),
      ],
      "group",
    ),
  ];
  return items;
};

function getDefaultSelectedKey(pathname: string) {
  const pathSegments = pathname.split("/").filter(Boolean);
  if (pathSegments.length === 0) return "services";
  return pathSegments[0];
}

export const LanguageSelect = ({
  className,
}: {
  className?: string | undefined;
}) => {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  useEffect(() => {
    const handleLanguageChange = () => {
      setCurrentLanguage(i18n.language);
    };

    i18n.on("languageChanged", handleLanguageChange);

    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [i18n]);

  const languages: MenuItem[] = [
    getItem(
      t("Language"),
      "grp1",
      null,
      [
        getItem(
          currentLanguage === "en" ? "English" : "Eesti",
          "lng",
          <LanguageIcon
            icon={currentLanguage === "en" ? UKIcon : EstoniaIcon}
          />,
          [
            getItem(
              <LanguageButton lng="ee" name="Eesti" />,
              "et",
              <LanguageIcon icon={EstoniaIcon} />,
            ),
            getItem(
              <LanguageButton lng="en" name="English" />,
              "en",
              <LanguageIcon icon={UKIcon} />,
            ),
          ],
        ),
      ],
      "group",
    ),
  ];

  return (
    <Menu
      className={className}
      theme="light"
      defaultSelectedKeys={["1"]}
      mode="inline"
      items={languages}
    />
  );
};

const CompanySelect = ({ className }: { className?: string | undefined }) => {
  const [customers] = useFetchData<Customer>(apiService.getCustomers, {});
  const [currentCustomer, setCurrentCustomer] = useState<Customer | null>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const localStorageCustomer = localStorage.getItem("customer");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const customer = await getCurrentCustomer();
        setCurrentCustomer(customer);
      } catch (error) {
        console.error("Error fetching customer data:", error);
      }
    };

    fetchData();
  }, [localStorageCustomer]);

  let companies: MenuItem[] = [];
  customers.map((customer: Customer) =>
    companies.push(
      getItem(
        <a
          href={"/" + getDefaultSelectedKey(window.location.pathname)}
          onClick={() => {
            localStorage.setItem("customer", JSON.stringify(customer));
            window.location.reload();
          }}
        >
          {customer.name}
        </a>,
        String(customer.id),
      ),
    ),
  );

  /*
    If there are no companies, show a button to add a company.
    Otherwise, show the companies and a button to add a company.
  */
  let items;
  if (companies.length === 0) {
    items = [
      getItem(
        t("Company"),
        "company",
        null,
        [
          getItem(
            <a href="/company/add" onClick={() => navigate("/company/add")}>
              <PlusOutlined /> {t("Add company")}
            </a>,
            "lisamine",
          ),
        ],
        "group",
      ),
    ];
  } else {
    companies.push(
      getItem(
        <a href="/company/add" onClick={() => navigate("/company/add")}>
          <PlusOutlined /> {t("Add company")}
        </a>,
        "lisamine",
      ),
    );
    items = [
      getItem(
        t("Company"),
        "company",
        null,
        [
          getItem(
            currentCustomer
              ? String(currentCustomer.name)
              : t("Choose company"),
            "firma",
            null,
            companies,
          ),
        ],
        "group",
      ),
    ];
  }

  return (
    <Menu
      className={className}
      theme="light"
      defaultSelectedKeys={[currentCustomer ? String(currentCustomer.id) : "1"]}
      mode="inline"
      items={items}
    />
  );
};

const DesktopSidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const labels: MenuLabels = {
    menuLabel: t("Menu"),
    servicesLabel: t("Services"),
    myServicesLabel: t("My Services"),
    dataConnectionsLabel: t("Integrations"),
    companyLabel: t("Settings"),
    feedbackLabel: t("Feedback"),
    helpLabel: t("Help"),
  };
  const items = createMenuItems(labels);
  return (
    <div
      style={{ width: collapsed ? "80px" : "300px" }}
      className="sidebar-wrapper"
    >
      <Sider
        className="sidebar desktop-sidebar"
        theme="light"
        width="300"
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        {!collapsed && (
          <a
            href="/services"
            onClick={() => {
              navigate("/services");
            }}
          >
            <h1
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              ANDMEAADU
            </h1>
          </a>
        )}
        <ConfigProvider
          theme={{
            components: {
              Menu: {
                itemSelectedBg: "white",
                itemHoverBg: "white",
                itemHeight: 50,
                itemSelectedColor: "black",
                itemColor: "#535353",
                groupTitleColor: "#0063f7",
                groupTitleFontSize: 15,
                groupTitleLineHeight: 2,
              },
            },
          }}
        >
          <CompanySelect className="desktop-sidebar-item" />
          <Menu
            className="desktop-sidebar-item"
            theme="light"
            mode="inline"
            items={items}
            selectedKeys={[getDefaultSelectedKey(window.location.pathname)]}
          />
          <LanguageSelect className="desktop-sidebar-item" />
        </ConfigProvider>
        <LogoutButton />
      </Sider>
    </div>
  );
};

const MobileSideBar = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { t } = useTranslation();
  const labels: MenuLabels = {
    menuLabel: t("Menu"),
    servicesLabel: t("Services"),
    myServicesLabel: t("My Services"),
    dataConnectionsLabel: t("Integrations"),
    companyLabel: t("Settings"),
    feedbackLabel: t("Feedback"),
    helpLabel: t("Help"),
  };
  const items = createMenuItems(labels);
  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setDrawerVisible(false);
  };

  return (
    <span>
      {/* Button to show the drawer (visible only on small screens) */}
      <Button
        shape="circle"
        type="primary"
        className="drawer-button"
        icon={<MenuOutlined />}
        onClick={showDrawer}
        size="large"
        style={{
          position: "fixed",
          height: "4rem",
          width: "4rem",
          zIndex: 1000,
          top: "88%",
          left: "77%",
        }}
      ></Button>

      {/* Drawer for mobile view */}
      <Drawer
        title="Menu"
        placement="right"
        closable={true}
        onClose={onCloseDrawer}
        open={drawerVisible}
        style={{ backgroundColor: "#f0f3f5" }}
      >
        {" "}
        <ConfigProvider
          theme={{
            components: {
              Menu: {
                itemSelectedBg: "white",
                itemHoverBg: "white",
                itemHeight: 50,
                itemSelectedColor: "black",
                itemColor: "#535353",
                groupTitleColor: "#0063f7",
                groupTitleFontSize: 15,
                groupTitleLineHeight: 2,
              },
            },
          }}
        >
          <CompanySelect className="mobile-sidebar-item" />
          <Menu
            className="mobile-sidebar-item"
            theme="light"
            selectedKeys={[getDefaultSelectedKey(window.location.pathname)]}
            mode="inline"
            items={items}
          />
          <LanguageSelect className="mobile-sidebar-item" />
        </ConfigProvider>
        <LogoutButton />
      </Drawer>

      {/* Regular sidebar for desktop view */}
    </span>
  );
};
const Sidebar = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return <>{windowWidth > 768 ? <DesktopSidebar /> : <MobileSideBar />}</>;
};

export default Sidebar;
