import React from "react";
import { Card, Layout } from "antd";
import CompanyForm from "../forms/Company";

const CompanyAddPage = () => {
  return (
    <Layout>
      <Card>
        <CompanyForm />
      </Card>
    </Layout>
  );
};

export default CompanyAddPage;
