import React, { useEffect, useState } from "react";
import useFetchData from "../hooks/useFetchData";
import {
  DeleteButton,
  HelpQuestionCircle,
  ModifyButton,
} from "./styledComponents";
import * as apiService from "../services/apiServices";
import { Divider, Form, message, Modal, Select, Table } from "antd";
import { displayError } from "../Helpers";
import { useTranslation } from "react-i18next";
import ButtonGroup from "antd/es/button/button-group";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";

interface TaxRelation {
  id: string;
  tax1: {
    tax_name: string;
    source: string;
    tax_vat_percent: number;
    tax_id: string;
    id: string;
  };
  tax2: {
    tax_name: string;
    source: string;
    tax_vat_percent: number;
    tax_id: string;
    id: string;
  };
}

interface Tax {
  id: string;
  customer: number;
  source: string;
  tax_id: string;
  tax_vat_code: string;
  tax_vat_percent: string;
  tax_name: string;
}

const CompanyTaxes = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const [taxesRelations, setTaxesRelations] = useState<TaxRelation[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [taxes] = useFetchData(apiService.getTaxes, {});
  const [currentTaxRelation, setCurrentTaxRelation] =
    useState<TaxRelation | null>(null);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [selectedSource1, setSelectedSource1] = useState<string | undefined>(
    undefined,
  );
  const [selectedSource2, setSelectedSource2] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiService.getTaxesRelations({
          page: currentPage,
          page_size: 10,
        });
        setTaxesRelations(response.results);
        setTotalCount(response.count);
      } catch (error) {
        console.error(error);
        setTaxesRelations([]);
        setTotalCount(0);
      }
    };
    fetchData();
  }, [currentPage, triggerFetch]);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      console.log("Received values of form: ", values);
      setIsModalVisible(false);
      form.resetFields();
      await apiService.updateTaxesRelation(
        values.tax1,
        values.tax2,
        currentTaxRelation?.id,
      );
      setTriggerFetch((prev) => !prev);
      if (currentTaxRelation) {
        message.success(t("Tax relation updated"));
      } else {
        message.success(t("Tax relation added"));
      }
    } catch (error) {
      message.error(
        t(displayError(error, "Error adding/updating tax relation")),
        6,
      );
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleRelationDelete = (relation_id: string) => {
    Modal.confirm({
      title: t("Are you sure you want to delete this tax relation?"),

      onOk: async () => {
        try {
          await apiService.deleteTaxRelation(relation_id);
          message.success(t("Tax relation deleted"));
          setTriggerFetch((prev) => !prev);
        } catch (error) {
          message.error(t(displayError(error, "Error deleting tax relation")));
        }
      },

      onCancel() {},
    });
  };

  const handleTableChange = (pagination: any) => {
    setCurrentPage(pagination.current);
    setTriggerFetch((prev) => !prev);
  };

  const showModal = (taxRelation?: TaxRelation) => {
    setCurrentTaxRelation(taxRelation ? taxRelation : null);
    form.resetFields();
    setSelectedSource1(taxRelation?.tax1.source);
    setSelectedSource2(taxRelation?.tax2.source);
    form.setFieldsValue({
      source1: taxRelation ? taxRelation.tax1.source : undefined,
      source2: taxRelation ? taxRelation.tax2.source : undefined,
      tax1: taxRelation ? taxRelation.tax1.id : undefined,
      tax2: taxRelation ? taxRelation.tax2.id : undefined,
    });
    setIsModalVisible(true);
  };

  const handleSourceChange1 = (value: string) => {
    setSelectedSource1(value);
    form.setFieldsValue({ tax1: undefined });
  };

  const handleSourceChange2 = (value: string) => {
    setSelectedSource2(value);
    form.setFieldsValue({ tax2: undefined });
  };

  const filteredTaxes1 = taxes
    .filter((tax: Tax) => tax.source === selectedSource1)
    .sort(
      (a: Tax, b: Tax) => Number(a.tax_vat_percent) - Number(b.tax_vat_percent),
    );
  const filteredTaxes2 = taxes
    .filter((tax: Tax) => tax.source === selectedSource2)
    .sort(
      (a: Tax, b: Tax) => Number(a.tax_vat_percent) - Number(b.tax_vat_percent),
    );

  const columns = [
    {
      title: t("Tax 1"),
      children: [
        {
          title: t("Source"),
          dataIndex: ["tax1", "source"],
          key: "tax1.source",
        },
        {
          title: t("Name"),
          dataIndex: ["tax1", "tax_name"],
          key: "tax1.tax_name",
        },
        {
          title: t("Percent"),
          dataIndex: ["tax1", "tax_vat_percent"],
          key: "tax1.tax_vat_percent",
          render: (text: string) =>
            `${Number(text) < 1 ? Number(text) * 100 : Number(text)}%`,
        },
      ],
    },
    {
      key: "relation",
      render: () => "-",
    },

    {
      title: t("Tax 2"),
      children: [
        {
          title: t("Percent"),
          dataIndex: ["tax2", "tax_vat_percent"],
          key: "tax2.tax_vat_percent",
          render: (text: string) =>
            `${Number(text) < 1 ? Number(text) * 100 : Number(text)}%`,
        },
        {
          title: t("Name"),
          dataIndex: ["tax2", "tax_name"],
          key: "tax2.tax_name",
        },

        {
          title: t("Source"),
          dataIndex: ["tax2", "source"],
          key: "tax2.source",
        },
      ],
    },

    {
      title: (
        <h1>
          <HelpQuestionCircle
            text={t(
              "Taxes relation is a way to link two taxes together between different systems. When object is copied from one system to another, the tax is set according to this table.",
            )}
          />
        </h1>
      ),
      key: "delete-edit",
      className: "table-last-cell",
      render: (_: any, record: TaxRelation) => (
        <ButtonGroup>
          <ModifyButton style={{ zIndex: 2 }} onClick={() => showModal(record)}>
            <EditOutlined />
          </ModifyButton>
          <DeleteButton onClick={() => handleRelationDelete(record.id)}>
            <DeleteOutlined />
          </DeleteButton>
        </ButtonGroup>
      ),
    },
  ];

  return (
    taxes &&
    new Set(taxes.map((tax: Tax) => tax.source)).size >= 2 && (
      <div>
        <h1>{t("Taxes Relation")}</h1>
        <Table
          columns={columns}
          scroll={{ x: "max-content" }}
          pagination={{
            current: currentPage,
            pageSize: 10,
            total: totalCount,
          }}
          style={{ marginBottom: "-48px" }}
          onChange={handleTableChange}
          dataSource={taxesRelations.sort((a, b) => {
            return a.tax1.tax_vat_percent - b.tax1.tax_vat_percent; // Sorting by tax1's VAT percent
          })}
          rowKey="id"
        />
        <Modal
          title={
            currentTaxRelation ? t("Edit Tax Relation") : t("Add Tax Relation")
          }
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form form={form} layout="vertical">
            <h3>{t("Tax 1")}</h3>
            <Form.Item
              name="source1"
              label={t("Source")}
              rules={[{ required: true }]}
            >
              <Select
                placeholder={t("Select a source")}
                onChange={handleSourceChange1}
                value={selectedSource1}
              >
                {Array.from(
                  new Set<string>(
                    taxes
                      .map((tax: Tax) => tax.source)
                      .filter((source: string) => source !== selectedSource2),
                  ),
                ).map((source: string) => (
                  <Select.Option key={source} value={source}>
                    {source}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="tax1"
              label={t("Tax")}
              rules={[{ required: true }]}
            >
              <Select placeholder={t("Select a tax")}>
                {filteredTaxes1.map((tax: Tax) => (
                  <Select.Option key={tax.id} value={tax.id}>
                    {`${Number(tax.tax_vat_percent) < 1 ? Number(tax.tax_vat_percent) * 100 : Number(tax.tax_vat_percent)}% - ${tax.tax_name}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Divider />
            <h3>{t("Tax 2")}</h3>
            <Form.Item
              name="source2"
              label={t("Source")}
              rules={[{ required: true }]}
            >
              <Select
                placeholder={t("Select a source")}
                onChange={handleSourceChange2}
                value={selectedSource2}
              >
                {Array.from(
                  new Set<string>(
                    taxes
                      .map((tax: Tax) => tax.source)
                      .filter((source: string) => source !== selectedSource1),
                  ),
                ).map((source: string) => (
                  <Select.Option key={source} value={source}>
                    {source}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="tax2"
              label={t("Tax")}
              rules={[{ required: true }]}
            >
              <Select placeholder={t("Select a tax")}>
                {filteredTaxes2.map((tax: Tax) => (
                  <Select.Option key={tax.id} value={tax.id}>
                    {`${Number(tax.tax_vat_percent) < 1 ? Number(tax.tax_vat_percent) * 100 : Number(tax.tax_vat_percent)}% - ${tax.tax_name}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
        <ModifyButton
          style={{ width: "20%", minWidth: "100px" }}
          onClick={() => showModal()}
        >
          <PlusOutlined />
        </ModifyButton>
      </div>
    )
  );
};

export default CompanyTaxes;
