import React from "react";
import Sidebar from "./sidebar/sidebar";
import { Content } from "antd/es/layout/layout";
import MainHeader from "./header";
import useRedirectIfLoggedOut from "../hooks/useRedirectIfLoggedOut";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";

const AuthenticatedBaseLayout: React.FC = () => {
  useRedirectIfLoggedOut();
  return (
    <Layout>
      <Sidebar />
      <Content className="main-content">
        <MainHeader />
        <Outlet />
      </Content>
    </Layout>
  );
};

export default AuthenticatedBaseLayout;
