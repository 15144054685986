import React from "react";
import * as apiService from "../services/apiServices";
import { Button, Row } from "antd";
import { DataConnection } from "../types";
import DataConnectionCard from "../components/dataConnectionCard";
import useFetchData from "../hooks/useFetchData";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const DataConnectionsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [connections] = useFetchData<DataConnection[]>(
    apiService.getUserDataConnections,
    {},
  );
  return (
    <div className="data-connections">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2>{t("Integrations")}</h2>
        <Button
          shape="round"
          type="primary"
          icon={<PlusOutlined />}
          style={{ float: "right" }}
          onClick={() => navigate("/data-connections/add")}
        >
          {t("Add")}
        </Button>
      </div>
      <Row className="data-connections-row" gutter={[0, 40]}>
        {connections.map((connection: DataConnection) => (
          <DataConnectionCard dataConnection={connection} />
        ))}
      </Row>
    </div>
  );
};

export default DataConnectionsPage;
