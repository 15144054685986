import { FeedbackCardProps } from "../types";
import React from "react";
import { Link } from "react-router-dom";
import { Card, Col, Steps } from "antd";
import { useTranslation } from "react-i18next";

const FeedbackCard: React.FC<FeedbackCardProps> = ({ feedback }) => {
  const { t } = useTranslation();
  const statusMapping: { [key: string]: number } = {
    opened: 0,
    analyzing: 1,
    closed: 2,
  };

  function getFeedbackStatusBar(currentStatus: string) {
    return (
      <Steps
        direction="vertical"
        size="small"
        current={statusMapping[currentStatus]}
        items={[
          { title: t("Opened") },
          { title: t("On analysis") },
          { title: t("Finished") },
        ]}
      />
    );
  }

  return (
    <Col className="feedback-column">
      <Link to={`/feedback/topic/${feedback.id}`}>
        <Card hoverable={true} className="data-connection-card">
          <div style={{ float: "left", flex: 1 }}>
            <h3>{feedback.title}</h3>
            {feedback.customer_service
              ? feedback.customer_service.service_name
              : t("Overall")}
          </div>
          <div style={{ float: "right", flex: 1 }}>
            {getFeedbackStatusBar(feedback.status)}
          </div>
        </Card>
      </Link>
    </Col>
  );
};

export default FeedbackCard;
