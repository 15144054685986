import { Button, Form, Input, message } from "antd";
import React from "react";
import * as apiService from "../services/apiServices";
import { markFeedbackClosed } from "../services/apiServices";
import { FeedbackQuestionFormProps } from "../types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { displayError } from "../Helpers";

const FeedbackQuestionForm: React.FC<FeedbackQuestionFormProps> = ({
  feedbackId,
  onSubmit,
}) => {
  const { TextArea } = Input;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const onFinish = async (values: any) => {
    try {
      const result = await apiService.createFeedbackEntry(
        values.text,
        feedbackId,
      );
      message.success(t(result));
    } catch (error) {
      message.error(
        t(displayError(error, "An error occurred while submitting feedback.")),
      );
    }
  };

  const closeFeedback = () => {
    markFeedbackClosed(feedbackId);
    navigate("/feedback/opened");
  };

  return (
    <Form onFinish={onFinish}>
      <Form.Item
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        label={t("Add message")}
        name="text"
      >
        <TextArea rows={8} name="textarea" />
      </Form.Item>
      <Form.Item>
        <Button shape="round" type="primary" htmlType="submit">
          {t("Submit")}
        </Button>
        <Button
          shape="round"
          type="primary"
          onClick={closeFeedback}
          style={{ float: "right", backgroundColor: "red" }}
        >
          {t("Finish")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FeedbackQuestionForm;
