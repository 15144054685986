import { DataConnectionProps } from "../types";
import React from "react";
import { Link } from "react-router-dom";
import { Card, Col } from "antd";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  MinusCircleFilled,
} from "@ant-design/icons";
import { dataConnectionLogos } from "../Helpers";
import { IconWrapper } from "./styledComponents";

const DataConnectionStatusIcon = ({ correct }: { correct: boolean }) => {
  let IconComponent = CloseCircleFilled;
  let color = "red";

  if (correct) {
    IconComponent = CheckCircleFilled;
    color = "green";
  } else if (correct === null) {
    IconComponent = MinusCircleFilled;
    color = "orange";
  }

  return (
    <IconWrapper color={color}>
      <IconComponent />
    </IconWrapper>
  );
};

const DataConnectionCard: React.FC<DataConnectionProps> = ({
  dataConnection,
}) => {
  const imageSource = dataConnectionLogos[dataConnection.name];
  const showImage = !!imageSource;

  return (
    <Col className="data-connection-column">
      <Link to={`/data-connections/${dataConnection.id}`}>
        <Card hoverable={true} className="data-connection-card">
          {showImage && (
            <img
              src={imageSource}
              alt={dataConnection.name}
              className="data-connection-logo"
            />
          )}
          <DataConnectionStatusIcon correct={dataConnection.correct_config} />
          <h3 style={{ marginTop: showImage ? "15px" : "60px" }}>
            {dataConnection.name}
          </h3>
        </Card>
      </Link>
    </Col>
  );
};

export default DataConnectionCard;
