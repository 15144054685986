import React from "react";
import { Card, Layout, Row, Empty } from "antd";
import { Feedback, FeedbackTabProps } from "../types";
import FeedbackCard from "./feedbackCard";
import FeedbackForm from "../forms/Feedback";

const FeedbackTab: React.FC<FeedbackTabProps> = ({ feedback }) => {
  if (feedback) {
    if (feedback.length === 0)
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    return (
      <Layout>
        <div className="data-connections">
          <Row className="data-connections-row" gutter={[0, 40]}>
            {feedback.map((fb: Feedback) => (
              <FeedbackCard feedback={fb} />
            ))}
          </Row>
        </div>
      </Layout>
    );
  } else {
    return (
      <Layout>
        <Card>
          <FeedbackForm />
        </Card>
      </Layout>
    );
  }
};

export default FeedbackTab;
