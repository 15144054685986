import { Button, Form, Input, message, Select } from "antd";
import { displayError } from "../Helpers";
import React, { useState } from "react";
import * as apiService from "../services/apiServices";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CompanyForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [acceptInvoice, setSelectedIntegration] = useState<boolean>(false);

  const handleSelect = async (value: boolean) => {
    setSelectedIntegration(value);
  };

  const handleSave = async (values: { [key: string]: any }) => {
    try {
      const customer = await apiService.createCustomer(values);
      message.success(t("Company added successfully"));
      localStorage.setItem("customer", JSON.stringify(customer));
      navigate("/company");
    } catch (error) {
      message.error(t(displayError(error, "Error adding company")));
    }
  };

  return (
    <Form
      onFinish={handleSave}
      requiredMark={false}
      initialValues={{
        accept_einvoice: false,
        email_language: "et",
      }}
    >
      <Form.Item
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 12 }}
        label={t("Company Name")}
        name="name"
        rules={[{ required: true, message: "" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 12 }}
        label={t("Email")}
        name="email"
        rules={[{ required: true, message: "" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 12 }}
        label={t("Email language")}
        name="email_language"
        rules={[{ required: true, message: "" }]}
      >
        <Select
          options={[
            { value: "et", label: t("Estonia") },
            { value: "en", label: t("English") },
          ]}
        />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 12 }}
        label={t("Accept e-invoice")}
        name="accept_einvoice"
      >
        <Select
          options={[
            { value: true, label: t("Yes") },
            { value: false, label: t("No") },
          ]}
          onChange={handleSelect}
        />
      </Form.Item>
      {acceptInvoice && (
        <>
          <Form.Item
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            label={t("Invoice email")}
            name="invoice_email"
            rules={[{ required: true, message: "" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            label={t("Invoice company")}
            name="invoice_company"
            rules={[{ required: true, message: "" }]}
          >
            <Input />
          </Form.Item>
        </>
      )}
      <Form.Item>
        <Button type="primary" htmlType="submit">
          {t("Add")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CompanyForm;
