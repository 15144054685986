import styled from "styled-components";
import { Button, Card, Popover } from "antd";
import React from "react";
import { SubmitButtonProps } from "../types";
import { QuestionCircleOutlined } from "@ant-design/icons";

export const CustomCard = styled(Card)`
  height: 100%;
`;

export const DeleteButton = styled(Button)`
  color: #ff4d4f;
  border-color: #ff4d4f;
  height: 100%;
  width: 100%;
  &:hover {
    background-color: #ff4d4f !important;
    color: white !important;
  }
`;

export const ModifyButton = styled(Button)`
  color: #4096ff;
  border-color: #4096ff;
  height: 100%;
  width: 100%;
  &:hover {
    background-color: #4096ff !important;
    color: white !important;
  }
`;

export const HelpQuestionCircle = ({ text }: { text: string }) => (
  <div style={{ float: "right" }}>
    <Popover content={<div style={{ maxWidth: "500px" }}>{text}</div>}>
      <QuestionCircleOutlined />
    </Popover>
  </div>
);

export const SubmitButton: React.FC<SubmitButtonProps> = ({
  children,
  ...props
}) => (
  <Button {...props} type="primary" htmlType="submit" block>
    {children}
  </Button>
);

export const IconWrapper = styled.span`
  float: right;
  color: ${(props) => props.color};
`;
