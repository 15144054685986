import React, { useEffect, useState } from "react";
import * as apiService from "../services/apiServices";
import { Col, Row } from "antd";

import { Service } from "../types";
import { useNavigate, useParams } from "react-router-dom";
import ServiceDonesTable from "../components/serviceDonesTable";
import "react-js-cron/dist/styles.css";
import useMobileDetect from "../hooks/useMobileDetect";
import useFetchData from "../hooks/useFetchData";
import ServiceSettings from "../components/serviceSettings";
import ServiceMainInfo from "../components/serviceMainInfo";
import { processError } from "../Helpers";
import { useTranslation } from "react-i18next";
import { CustomCard } from "../components/styledComponents";

const ServiceDetails = ({ editMode }: { editMode: boolean }) => {
  const navigate = useNavigate();
  const { serviceId } = useParams();
  const [service, setService] = useState<Service>({} as Service);
  const [serviceDoneValueName, setServiceDoneValueName] = useState("Value");
  const isSmaller1024 = useMobileDetect(1024);
  const { t } = useTranslation();
  const [services, error] = useFetchData(apiService.getUserServices, {
    id: serviceId,
  });
  useEffect(() => {
    if (services && Object.keys(services).length > 0) {
      setService(services[0]);
    }
  }, [services]);

  useEffect(() => {
    if (service.settings?.service_done_value_name) {
      setServiceDoneValueName(service.settings.service_done_value_name);
    }
  }, [service]);

  if (error || !serviceId || !service) {
    navigate(processError(error));
  }

  return (
    <Row gutter={[isSmaller1024 ? 0 : 30, 30]} style={{ width: "100%" }}>
      <Col xl={12} xs={24}>
        <CustomCard>
          <ServiceMainInfo service={service} setService={setService} />
        </CustomCard>
      </Col>
      <Col xl={12} xs={24}>
        <CustomCard>
          <h2>{t("Settings")}</h2>
          <ServiceSettings
            service={service}
            editMode={editMode}
          ></ServiceSettings>
        </CustomCard>
      </Col>
      <Col span={24}>
        <h2>{t("Synchronization results")}</h2>
        {service.id && (
          <ServiceDonesTable
            service_id={service.id}
            service_done_value_name={serviceDoneValueName}
          ></ServiceDonesTable>
        )}
      </Col>
    </Row>
  );
};

export default ServiceDetails;
