import React from "react";
import { useNavigate } from "react-router-dom";

const FeedbackRedirect = () => {
  let navigate = useNavigate();
  React.useEffect(() => {
    navigate("/feedback/new");
  }, [navigate]);

  return null;
};

export default FeedbackRedirect;
