import React, { useEffect, useState } from "react";
import * as apiService from "../services/apiServices";
import type { TabsProps } from "antd";
import { Tabs } from "antd";
import { DataConnection, Feedback } from "../types";
import useFetchData from "../hooks/useFetchData";
import FeedbackTab from "../components/feedbackTab";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FeedbackPage = () => {
  const { activeTab } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(activeTab || "new");
  const [feedback] = useFetchData<DataConnection[]>(
    apiService.getUserFeedback,
    {},
  );
  const openFeedback = feedback.filter(
    (fb: Feedback) => fb.status !== "closed",
  );
  const closedFeedback = feedback.filter(
    (fb: Feedback) => fb.status === "closed",
  );

  useEffect(() => {
    if (!activeTab || !["new", "opened", "closed"].includes(activeTab)) {
      navigate("/404");
    }
    setCurrentTab(activeTab || "new");
  }, [activeTab, navigate]);
  const items: TabsProps["items"] = [
    {
      key: "new",
      label: t("Open new topic"),
      children: <FeedbackTab feedback={null} />,
    },
    {
      key: "opened",
      label: t("Opened"),
      children: <FeedbackTab feedback={openFeedback} />,
    },
    {
      key: "closed",
      label: t("Closed"),
      children: <FeedbackTab feedback={closedFeedback} />,
    },
  ];

  return (
    <div className="data-connections">
      <h2>{t("Feedback")}</h2>
      <Tabs defaultActiveKey={currentTab} items={items} />
    </div>
  );
};

export default FeedbackPage;
