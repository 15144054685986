import { parseExpression } from "cron-parser";
import { toast } from "react-toastify";

import scoro_logo from "./data_connection_logos/scoro_logo.png";
import merit_logo from "./data_connection_logos/merit_logo.png";
import directo_logo from "./data_connection_logos/directo_logo.png";
import shopify_logo from "./data_connection_logos/shopify_logo.png";
import elko_logo from "./data_connection_logos/elko_logo.png";
import erply_logo from "./data_connection_logos/erply_logo.jpg";
import also_logo from "./data_connection_logos/also_logo.jpeg";
import klaviyo_logo from "./data_connection_logos/klaviyo_logo.png";
import fondion_logo from "./data_connection_logos/fondion_logo.jpeg";
import digigeenius_logo from "./data_connection_logos/digigeenius_logo.png";
import woocommerce_logo from "./data_connection_logos/woocommerce_logo.png";
import moderan_logo from "./data_connection_logos/moderan_logo.png";
import { CustomAxiosError, Customer } from "./types";
import * as apiService from "./services/apiServices";
import { AxiosError } from "axios";
import i18n from "i18next";

const estonianDateFormatter = new Intl.DateTimeFormat("et-EE", {
  year: "numeric",
  month: "short",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
});

const englishDateFormatter = new Intl.DateTimeFormat("en-UK", {
  year: "numeric",
  month: "short",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
});

export const getNextTriggerTime = (cronExpression: string): string => {
  try {
    const customer = localStorage.getItem("customer");
    if (!customer) {
      return "";
    }
    const data = JSON.parse(customer);

    const options = {
      currentDate: new Date(),
      tz: data.timezone,
    };
    const interval = parseExpression(cronExpression, options);
    const nextDate = interval.next().toDate();

    return i18n.language === "ee"
      ? estonianDateFormatter.format(nextDate)
      : englishDateFormatter.format(nextDate);
  } catch (err) {
    if (err instanceof RangeError) {
      console.warn("Invalid date", cronExpression);
    } else {
      console.error(err);
    }
    return "";
  }
};

export function formatDate(dateString: string): string {
  const date = new Date(dateString);

  try {
    return i18n.language === "ee"
      ? estonianDateFormatter.format(date)
      : englishDateFormatter.format(date);
  } catch (err) {
    if (err instanceof RangeError) {
      console.warn("Invalid date", dateString);
    } else {
      console.error(err);
    }
    return "";
  }
}

export const estonianLocale = {
  everyText: "Iga",
  emptyMonths: "kõik kuud",
  emptyMonthDays: "kõik päevad",
  emptyWeekDays: "kõik nädalapäevad",
  emptyHours: "kõik tunnid",
  emptyMinutes: "kõik minutid",
  emptyMinutesForHourPeriod: "kõik minutid",
  emptyMonthDaysShort: "iga päev",
  suffixMinutesForHourPeriod: "minut",
  yearOption: "aasta",
  monthOption: "kuu",
  weekOption: "nädal",
  dayOption: "päev",
  hourOption: "tund",
  prefixPeriod: "Iga",
  prefixMonths: "kuudel",
  prefixMonthDays: "kuupäevadel",
  prefixWeekDays: "nädalapäevadel",
  prefixWeekDaysForMonthAndYearPeriod: "nädalapäevadel",
  prefixMinutesForHourPeriod: "tundidel",
  prefixMinutes: "iga",
  prefixHours: "tundidel",
  minuteOption: "minut",
  rebootOption: "Taaskäivitus",
  errorInvalidCron: "Vigane cron avaldis",
  clearButtonText: "Tühjenda",
  weekDays: [
    "Pühapäev",
    "Esmaspäev",
    "Teisipäev",
    "Kolmapäev",
    "Neljapäev",
    "Reede",
    "Laupäev",
  ],
  months: [
    "Jaanuar",
    "Veebruar",
    "Märts",
    "Aprill",
    "Mai",
    "Juuni",
    "Juuli",
    "August",
    "September",
    "Oktoober",
    "November",
    "Detsember",
  ],
  altWeekDays: ["PÜH", "ESM", "TEI", "KOL", "NEL", "REE", "LAU"],
  altMonths: [
    "JAN",
    "VEB",
    "MÄR",
    "APR",
    "MAI",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OKT",
    "NOV",
    "DET",
  ],
};

export function adjustCronValue(cronValue: string) {
  let cronParts = cronValue.split(" ");
  if (cronParts.length === 5) {
    cronParts[0] = "0";
  }
  return cronParts.join(" ");
}

export function getOnlyNeededKeys(
  items: Map<string, string>,
): [string, string][] {
  return Array.from(Object.entries(items)).filter(
    ([key, _]) => dataConnectionDetailsMap[key],
  );
}

export const dataConnectionDetailsMap: { [key: string]: string } = {
  // Common
  api_key: "API key",

  // Scoro
  lang: "Language",
  domain: "Domain",
  company_account_id: "Company account ID",

  //Merit
  api_id: "API id",

  //Directo
  api_url: "API url",

  //Token
  token: "Token",

  //Elko, Erply
  username: "Username",
  password: "Password",
  customer_code: "Customer code",
  client_id: "Client ID",
  route_code: "Suunakood",

  // Fondion
  client_secret: "Client secret",
  tenant_id: "Tenant ID",
};

export const dataConnectionPrivateFields = ["api_key", "token", "password"];

export function getSuccessNotification(text: string) {
  return toast.success(text, {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 4000,
  });
}

export function getErrorNotification(text: string) {
  return toast.error(text, {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 4000,
  });
}

export const dataConnectionLogos: { [key: string]: string } = {
  Scoro: scoro_logo,
  Merit: merit_logo,
  Directo: directo_logo,
  Shopify: shopify_logo,
  Elko: elko_logo,
  Erply: erply_logo,
  Also: also_logo,
  Klaviyo: klaviyo_logo,
  Fondion: fondion_logo,
  DigiGeenius: digigeenius_logo,
  Woocommerce: woocommerce_logo,
  Moderan: moderan_logo,
};

export function getServiceLogos(serviceName: string) {
  const words = serviceName.split(/(?=[A-Z])/g) || [];
  const allowedWords = [
    "Merit",
    "Directo",
    "Scoro",
    "Shopify",
    "Elko",
    "Erply",
    "Also",
    "Klaviyo",
    "Fondion",
    "DigiGeenius",
    "Woocommerce",
    "Moderan",
  ];
  const sericeNames = words.filter((name) => allowedWords.includes(name));
  return sericeNames.map((name) => dataConnectionLogos[name]);
}

export async function getCurrentCustomer() {
  const getCustomer = async () => {
    try {
      const customers: Customer[] = await apiService.getCustomers();
      const customer = customers[0];
      localStorage.setItem("customer", JSON.stringify(customer));
      return customer;
    } catch (error) {
      return null;
    }
  };

  const customerString = localStorage.getItem("customer");
  let current_customer: Customer | null;
  try {
    current_customer = customerString ? JSON.parse(customerString) : null;
  } catch (error) {
    current_customer = await getCustomer();
  }

  if (!current_customer) {
    current_customer = await getCustomer();
  }

  return current_customer;
}

export function processError(error: any) {
  if (error instanceof Error && "response" in error) {
    const axiosError = error as AxiosError;
    if (axiosError.response && axiosError.response.status) {
      return "/" + axiosError.response.status;
    }
  }
  return "/404";
}

export function displayError(error: any, generic_error: string): string {
  if (error instanceof Error && "response" in error) {
    const axiosError = error as CustomAxiosError;
    if (axiosError.response && axiosError.response.data) {
      return (
        axiosError.response.data.response ||
        axiosError.response.data.detail ||
        Object.values(axiosError.response.data)[0] ||
        generic_error
      );
    }
  }
  return generic_error;
}

export const passwordValidationRule = (t: (key: string) => string) => ({
  validator(_: any, value: string) {
    if (value) {
      const hasLowerCase = /[a-z]/.test(value);
      const hasUpperCase = /[A-Z]/.test(value);
      const hasNumbers = /\d/.test(value);
      const isLongEnough = value.length >= 8; // Minimum 8 characters
      const isNotTooLong = value.length < 64;

      if (
        !hasLowerCase ||
        !hasUpperCase ||
        !hasNumbers ||
        !isLongEnough ||
        !isNotTooLong
      ) {
        return Promise.reject(
          new Error(
            t(
              "Password must be 8-64 characters long and contain upper and lowercase letters and numbers",
            ),
          ),
        );
      }
    }
    return Promise.resolve();
  },
});

export const confirmPasswordValidationRule = (
  getFieldValue: (fieldName: string) => string,
  t: (key: string) => string,
) => ({
  validator(_: any, value: string) {
    const password = getFieldValue("password");
    if (password && password !== value) {
      return Promise.reject(new Error(t("Entered passwords don't match")));
    }
    return Promise.resolve();
  },
});

export const formatErrorMessage = (
  errorData: { [key: string]: any },
  translator: any,
) => {
  if (!errorData || !errorData["message"]) {
    return null;
  }
  let message = translator(errorData["message"]);
  if (Array.isArray(errorData["args"])) {
    errorData["args"].forEach((arg: string) => {
      message = message.replace("%s", arg);
    });
  } else {
    console.warn(
      "Expected 'args' to be an array, but it was:",
      errorData["args"],
    );
  }

  return message;
};
