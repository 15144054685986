import { Col, List } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as apiService from "../services/apiServices";
import { Feedback, FeedbackDiscussionEntry } from "../types";
import useFetchData from "../hooks/useFetchData";
import FeedbackQuestionForm from "../forms/FeedbackQuestion";
import AuthenticatedBaseLayout from "../components/authenticatedBaseLayout";
import { processError } from "../Helpers";

const FeedbackDetails = () => {
  const navigate = useNavigate();
  const { feedbackId } = useParams();
  const [feedbackArray, error, fetchData] = useFetchData(
    apiService.getUserFeedback,
    { id: feedbackId },
  );
  const [feedback, setFeedback] = useState<Feedback>();
  const [entries, setEntries] = useState<FeedbackDiscussionEntry[]>();

  useEffect(() => {
    if (feedbackArray && Object.keys(feedbackArray).length > 0) {
      setFeedback(feedbackArray[0]);
      setEntries(feedbackArray[0]["entries"]);
    }
  }, [feedbackArray]);

  if (error || !feedbackId) {
    navigate(processError(error));
  }
  if (!feedback || !entries || !feedbackId) return <AuthenticatedBaseLayout />;

  const data = entries
    .map((entry: FeedbackDiscussionEntry) => ({
      text: entry.text,
      time: entry.time,
      author: entry.author,
    }))
    .slice()
    .sort((a, b) => {
      return a.time.localeCompare(b.time);
    });

  const updateFeedback = () => {
    fetchData();
  };

  return (
    <Col xxl={18} xl={24} lg={24} md={24}>
      <List
        size="large"
        header={<h3>{feedback.title}</h3>}
        bordered
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              title={<span style={{ fontWeight: "lighter" }}>{item.text}</span>}
              description={`${item.time} - ${item.author}`}
            />
          </List.Item>
        )}
      />
      {feedback.status !== "closed" && (
        <FeedbackQuestionForm
          feedbackId={feedbackId}
          onSubmit={updateFeedback}
        />
      )}
    </Col>
  );
};

export default FeedbackDetails;
