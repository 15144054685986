import React, { useEffect, useState } from "react";
import {
  Descriptions,
  DescriptionsProps,
  Form,
  Input,
  message,
  Switch,
} from "antd";
import * as apiService from "../services/apiServices";
import { EditableSettingsProps, Settings } from "../types";
import { useTranslation } from "react-i18next";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import { displayError } from "../Helpers";
import { DeleteButton, ModifyButton } from "./styledComponents";
import { useNavigate } from "react-router-dom";

const ServiceSettings: React.FC<EditableSettingsProps> = ({
  service,
  editMode,
}) => {
  const [settings, setSettings] = useState<Settings>(service.settings || {});

  useEffect(() => {
    if (service.settings && Object.keys(service.settings).length > 0) {
      setSettings(service.settings);
    } else if (
      service.configuration_schema &&
      service.configuration_schema.properties
    ) {
      const defaultSettings: Settings = {};
      for (const key in service.configuration_schema.properties) {
        const property = service.configuration_schema.properties[key];
        if (!(key in defaultSettings) && property.hasOwnProperty("default")) {
          defaultSettings[key] = property.default;
        }
      }
      setSettings(defaultSettings);
    }
  }, [service]);
  return (
    <div>
      {editMode ? (
        <ServiceSettingsUpdate
          service={service}
          settings={settings}
          setSettings={setSettings}
        />
      ) : (
        <ServiceSettingsView service={service} settings={settings} />
      )}
    </div>
  );
};

const ServiceSettingsView: React.FC<EditableSettingsProps> = ({
  service,
  settings,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleEditClick = () => {
    navigate(`/services/${service.id}/edit`);
  };

  if (
    !service.configuration_schema?.properties ||
    !settings ||
    Object.keys(settings).length === 0
  )
    return null;

  const myServiceItems: DescriptionsProps["items"] = Object.entries(
    service.configuration_schema.properties,
  )
    .filter(
      ([key, _]) =>
        settings[key] !== undefined &&
        settings[key] !== null &&
        settings[key] !== "",
    )
    .map(([key, config]) => {
      const value =
        typeof settings[key] === "boolean"
          ? settings[key]
            ? t("Yes")
            : t("No")
          : settings[key];

      return {
        key: key,
        label: t(config.title),
        children: value,
      };
    });

  return (
    <div>
      <Descriptions
        layout="horizontal"
        labelStyle={{ width: "50%", minWidth: "120px" }}
        contentStyle={{
          width: "100%",
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
        column={1}
        items={myServiceItems}
      />
      <ModifyButton type="default" onClick={handleEditClick}>
        <EditOutlined />
      </ModifyButton>
    </div>
  );
};

const ServiceSettingsUpdate: React.FC<EditableSettingsProps> = ({
  service,
  settings,
  setSettings,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleUserEditCancel = () => {
    navigate(`/services/${service.id}`);
  };

  const onFinish = async (values: any) => {
    let parsedValues: any = {};

    if (
      service.configuration_schema &&
      service.configuration_schema.properties
    ) {
      for (const [key, value] of Object.entries(values)) {
        if (
          typeof service.configuration_schema.properties[key] !== "undefined" &&
          service.configuration_schema.properties[key].type === "integer"
        ) {
          if (typeof value === "string") {
            parsedValues[key] = parseInt(value, 10);
          } else {
            parsedValues[key] = value;
          }
        } else {
          parsedValues[key] = value;
        }
      }
    }

    try {
      await apiService.updateCustomerService(
        service.id,
        parsedValues,
        service.enabled,
        service.cron,
      );
      message.success(t("Settings updated"));
      if (setSettings) {
        setSettings(parsedValues);
      }
      navigate(`/services/${service.id}`);
    } catch (e) {
      message.error(t(displayError(e, "Error updating settings")));
    }
  };

  if (
    !service.configuration_schema?.properties ||
    !settings ||
    Object.keys(settings).length === 0
  )
    return null;
  return (
    <Form
      onFinish={onFinish}
      initialValues={settings}
      labelCol={{ span: 8 }}
      size="middle"
      wrapperCol={{ span: 32 }}
      style={{ maxWidth: 1200 }}
      layout="vertical"
    >
      {Object.entries(service.configuration_schema.properties).map(
        ([key, config]) => (
          <Form.Item label={t(config.title)} name={key}>
            {config.type === "boolean" ? (
              <Switch defaultChecked={settings[key]} />
            ) : (
              <Input
                defaultValue={settings[key]}
                value={settings[key]}
                type={config.type === "integer" ? "number" : config.type}
              />
            )}
          </Form.Item>
        ),
      )}
      <Form.Item>
        <ModifyButton style={{ maxWidth: "20rem" }} htmlType="submit">
          {t("Update")}
        </ModifyButton>
      </Form.Item>
      <DeleteButton
        style={{ maxWidth: "20rem" }}
        type="default"
        onClick={handleUserEditCancel}
        danger
      >
        <CloseOutlined />
      </DeleteButton>
    </Form>
  );
};

export default ServiceSettings;
