import { Card } from "antd";
// import {useTranslation} from "react-i18next";

import feedback_image from "../images/help_page/feedback.png";
import customer_service from "../images/help_page/customer_service.png";
import search from "../images/help_page/search.png";
import service_done from "../images/help_page/service_done.png";

const HelpPage = () => {
  // const {t, i18n} = useTranslation();
  // TODO -> until the whole app is finished, the help page will be brought into
  //  its final form and then the translation will be added

  return (
    <>
      <h2>Korduma kippuvad küsimused</h2>
      <Card className="help-card">
        <h3>Mis leht on andmeaadu.ee ning milleks seda kasutada?</h3>
        <p>
          Andmeaadu on Red Functioni loodud integratsioonide haldusprogramm.
          Siin on meie klientidel võimalik hallata enda ettevõtete
          integratsioonide seadistusi, vaadata statistikat ning otsida andmete
          kopeerimisega seotud informatsiooni.
        </p>
      </Card>
      <Card className="help-card">
        <h3>Kuidas muuta andmete edastamise seadeid?</h3>
        <p>
          Andmete edaastamise seadete muutmiseks mine lehele <i>Teenused</i>.
          Sealt klikka huvipakkuva teenuse peale. Teenuse lehe alla on mugavalt
          koondatud kogu sünkroniseerimsega seotud seadistamine.
        </p>
        <img
          src={customer_service}
          alt={"customer service"}
          className="help-image"
        />
        <p>
          <i>Käivita teenus kohe</i> - võimaldab mugavalt endale vajalikul
          hetkel andmeid kopeerida.
        </p>
        <p>
          <i>Teenuse sünkroniseerimise intervall</i> - võimaldab seadistada
          ajavahemiku, mille tagant teenus ennast käivitab(vastavalt ettevõtte
          ajatsoonile).
        </p>
      </Card>
      <Card className="help-card">
        <h3>Kuidas otsida kopeeritud andmeid?</h3>
        <p>
          Ava huvipakkuv teenus. Avanenud lehelt võib leida kopeerimistulemuste
          tabeli. Veeru <i>Väärtus</i> kaudu saab otsida dokumendi, arve,
          kliendi jne numbri järgi vajalikke andmeid ning need seejärel
          kustutada.
        </p>
        <img src={search} alt={"search"} className="help-image-tiny" />
      </Card>
      <Card className="help-card">
        <h3>Kuidas uuendada juba kopeeritud andmeid?</h3>
        <p>
          Ava huvipakkuv teenus. Avanenud lehelt võib leida kopeerimistulemuste
          tabeli. Veeru Väärtus kaudu saab otsida dokumendi, arve, kliendi jne
          numbri järgi vajalikke andmeid. Veeru <i>Viimati muudetud</i> kaudu
          saab otsida huvipakkuva ajaperioodi kaudu andmeid.
        </p>
        <img
          src={service_done}
          alt={"service done"}
          className="help-image-small"
        />
        <p>Muudatustega andmete uuendamiseks käivita teenus uuesti.</p>
      </Card>
      <Card className="help-card">
        <h3>
          Kas juba sünkroniseeritud andmeid on võimalik ka sihtkohast täielikult
          kustutada?
        </h3>
        <p>
          Kahjuks sellist funktsionaalsust meie liidesed ei paku. Võimalus on
          kustutada kopeeringu kirje ning andmed üle kirjutada.
        </p>
      </Card>
      <Card className="help-card">
        <h3>
          Kas soovid anda tagasisidet või sul on ideid uute funktsioonide osas?
        </h3>
        <p>
          Kasuta selleks meie tagasiside vormi. Andmeaadu teenus on heade ideede
          ning tagasiside osas avatud. Soovime, et pakutav teenus kataks iga
          kliendi huvisid.
        </p>
        <img src={feedback_image} alt={"feedback"} className="help-image" />
      </Card>
    </>
  );
};

export default HelpPage;
