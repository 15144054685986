import { Button, Form, message, Select } from "antd";
import { displayError, estonianLocale } from "../Helpers";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchData from "../hooks/useFetchData";
import * as apiService from "../services/apiServices";
import { useNavigate } from "react-router-dom";
import { Cron } from "react-js-cron";
import i18n from "i18next";

const MyServiceAddForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [UserServices] = useFetchData(apiService.getUserServicesOptions, {});
  const [UserServicesNames, setUserServicesNames] = useState();
  const [selectedIntegration, setSelectedIntegration] = useState<string>("");
  const [cronValue, setCronValue] = useState("0 */6 * * *");

  useEffect(() => {
    if (UserServices) {
      setUserServicesNames(
        UserServices.map((item: { id: string; name: string }) => ({
          value: item.id,
          label: item.name,
        })),
      );
    }
  }, [UserServices]);

  const handleSelect = async (value: string) => {
    setSelectedIntegration(value);
  };

  const handleSave = async (values: { [key: string]: string }) => {
    try {
      delete values["userServicesName"];
      await apiService.createUserServices(selectedIntegration, cronValue);
      message.success(t("Service added successfully"));
      navigate("/services/my-services");
    } catch (error) {
      message.error(t(displayError(error, "Adding new service failed")));
    }
  };

  return (
    <Form onFinish={handleSave}>
      <Form.Item
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 12 }}
        label={t("Select service to add")}
        name="userServicesName"
      >
        <Select options={UserServicesNames} onChange={handleSelect} />
      </Form.Item>
      {selectedIntegration && (
        <>
          <Form.Item>
            <Cron
              value={cronValue}
              clearButton={false}
              setValue={setCronValue}
              locale={i18n.language === "ee" ? estonianLocale : undefined}
              allowedDropdowns={[
                "period",
                "months",
                "month-days",
                "week-days",
                "hours",
              ]}
              allowedPeriods={["year", "month", "week", "day"]}
              className="cron"
            />{" "}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t("Add")}
            </Button>
          </Form.Item>
        </>
      )}
    </Form>
  );
};

export default MyServiceAddForm;
