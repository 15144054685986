import useRedirectIfLoggedOut from "../hooks/useRedirectIfLoggedOut";
import useRedirectIfLoggedIn from "../hooks/useRedirectIfLoggedIn";
import { Layout } from "antd";

const HomePage = () => {
  useRedirectIfLoggedIn();
  useRedirectIfLoggedOut();
  return <Layout />;
};

export default HomePage;
