import React from "react";
import { Card, Layout } from "antd";
import MyServiceAddForm from "../forms/MyServiceAdd";

const MyServiceAddPage = () => {
  return (
    <Layout>
      <Card>
        <MyServiceAddForm />
      </Card>
    </Layout>
  );
};

export default MyServiceAddPage;
