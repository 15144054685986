import React, { useState } from "react";
import TimezoneSelect, {
  type ITimezone,
  ITimezoneOption,
} from "react-timezone-select";

interface TimezoneSelectorProps {
  initialTimezone: string;
  onUpdate: (newTimezone: string) => void;
}

const TimezoneSelector: React.FC<TimezoneSelectorProps> = ({
  initialTimezone,
  onUpdate,
}) => {
  const [selectedTimezone, setSelectedTimezone] =
    useState<ITimezone>(initialTimezone);
  const handleChange = (timezone: ITimezoneOption) => {
    setSelectedTimezone(timezone);
    onUpdate(timezone.value);
  };

  return <TimezoneSelect value={selectedTimezone} onChange={handleChange} />;
};

export default TimezoneSelector;
