import React from "react";
import { Alert, Button, Col, Modal, Spin } from "antd";
import { ImageListProps, ServiceProps, ServiceStatusProps } from "../types";
import { formatDate, getNextTriggerTime, getServiceLogos } from "../Helpers";
import * as apiService from "../services/apiServices";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  LoadingOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { CustomCard } from "./styledComponents";

const ServiceLogos: React.FC<ImageListProps> = ({ sources }) => {
  if (sources.length === 1) {
    return (
      <div style={{ display: "flex" }}>
        <img src={sources[0]} alt={sources[0]} className="service-logo" />
      </div>
    );
  } else {
    return (
      <div style={{ display: "flex" }}>
        <img src={sources[0]} alt={sources[0]} className="service-logo" />
        <FontAwesomeIcon icon={faArrowRightLong} className="right-arrow" />
        <img src={sources[1]} alt={sources[1]} className="service-logo" />
      </div>
    );
  }
};

const ServiceCard: React.FC<ServiceProps> = ({ service }) => {
  const { t } = useTranslation();
  const serviceLogos = getServiceLogos(service.service_name);

  return (
    <Col className="services-columns ">
      <Link to={`/services/${service.id}`}>
        <CustomCard hoverable={true}>
          <StatusCircle enabled={service.enabled} />
          <ServiceLogos sources={serviceLogos} />
          <h4>{t(service.service_name)}</h4>
          <div style={{ float: "left", paddingRight: "7.5rem" }}>
            <div>
              {t("Last run")}{" "}
              {service.last_run ? formatDate(service.last_run) : t("missing")}
            </div>
            <div>
              {t("Next run")}{" "}
              {service.cron ? getNextTriggerTime(service.cron) : t("missing")}{" "}
            </div>
          </div>
          <div
            style={{
              zIndex: "1",
              position: "absolute",
              bottom: "0",
              margin: "0 24px 26px 0",
              right: "0",
            }}
          >
            <ServiceStatus
              status={service.status}
              service_id={service.id}
              detailed={false}
            ></ServiceStatus>
          </div>
        </CustomCard>
      </Link>
    </Col>
  );
};

const StatusCircle = ({ enabled }: { enabled: boolean }) => {
  return enabled ? (
    <CheckCircleFilled style={{ float: "right", color: "green" }} />
  ) : (
    <CloseCircleFilled style={{ float: "right", color: "red" }} />
  );
};

export const ServiceStatus: React.FC<ServiceStatusProps> = ({
  status,
  service_id,
  detailed = false,
}) => {
  const { t } = useTranslation();
  if (status === "running") {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
      <div>
        {detailed ? (
          <div>
            <Alert
              type="info"
              message={
                <div>
                  <Spin indicator={antIcon} />{" "}
                  <span style={{ marginLeft: "15px" }}>
                    {t("Service is running")}
                  </span>
                </div>
              }
            ></Alert>
          </div>
        ) : (
          <Spin indicator={antIcon} />
        )}
      </div>
    );
  } else if (status === "error") {
    return (
      <Alert
        message={detailed ? t("Error occurred during last run") : t("Error")}
        type="warning"
        showIcon
      />
    );
  } else {
    return (
      <Button
        onClick={() => {
          Modal.confirm({
            title: t("Are you sure you want to run this service?"),

            onOk: () => {
              apiService.runCustomerService(service_id);
            },
          });
        }}
        shape="round"
        type="primary"
        icon={<PlayCircleOutlined />}
      >
        {detailed ? t("Run service now") : t("Run")}
      </Button>
    );
  }
};

export default ServiceCard;
