import React, { useEffect, useState } from "react";
import { Alert, Form, Input, message, Typography } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import * as apiService from "../services/apiServices";
import { CustomAxiosError } from "../types";
import { useTranslation } from "react-i18next";
import {
  confirmPasswordValidationRule,
  displayError,
  passwordValidationRule,
} from "../Helpers";
import { SubmitButton } from "../components/styledComponents";

const { Text, Title } = Typography;

const NormalLoginForm = () => {
  const navigate = useNavigate();
  const { resetToken } = useParams();
  const { t } = useTranslation();
  const [errorBanner, setErrorBanner] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      if (resetToken) {
        try {
          await apiService.checkPasswordReset(resetToken);
        } catch (error) {
          message.error(t(displayError(error, "Error with reset token")));
          navigate("/login");
        }
      } else {
        navigate("/login");
      }
    };
    fetchData();
  }, [resetToken, navigate, t]);
  const [form] = Form.useForm();
  if (!resetToken) return null;
  const onFinish = async (values: any) => {
    try {
      await apiService.resetPasswordConfirm(values.password, resetToken);
      message.success(t("Password updated!"));
      navigate("/login");
    } catch (error) {
      if (error instanceof Error && "response" in error) {
        const axiosError = error as CustomAxiosError;
        if (
          axiosError.response &&
          axiosError.response.data &&
          "detail" in axiosError.response.data
        ) {
          setErrorBanner(axiosError.response.data.detail);
        } else {
          setErrorBanner(t("Error creating user"));
        }
        console.error(error);
      }
    }
  };

  return (
    <Form
      name="registration"
      size="large"
      className="registrationForm"
      initialValues={{
        remember: true,
      }}
      form={form}
      onFinish={onFinish}
    >
      <Title>{t("Update password")}</Title>
      <Text type="secondary">{t("Enter new password")}</Text>

      <Form.Item
        name="password"
        hasFeedback
        rules={[passwordValidationRule(t)]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder={t("Password")}
        />
      </Form.Item>

      <Form.Item
        name="confirm"
        dependencies={["password"]}
        hasFeedback
        rules={[confirmPasswordValidationRule(form.getFieldValue, t)]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder={t("Confirm password")}
        />
      </Form.Item>

      <Form.Item>
        <SubmitButton>{t("Update")}</SubmitButton>
      </Form.Item>

      {errorBanner && (
        <Alert
          message={t("Error")}
          description={errorBanner}
          type="error"
          showIcon
        />
      )}
    </Form>
  );
};

export default NormalLoginForm;
