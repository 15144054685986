import { useState } from "react";
import * as apiService from "../services/apiServices";
import { message } from "antd";
import { HelpQuestionCircle, ModifyButton } from "./styledComponents";
import { useTranslation } from "react-i18next";
import { displayError } from "../Helpers";

const TokenButton = () => {
  const [token, setToken] = useState();
  const { t } = useTranslation();
  const fetchToken = async () => {
    try {
      const token = await apiService.getToken();
      setToken(token.token);
      message.success(t("New token fetched!"));
    } catch (error) {
      message.error(displayError(error, t("Error fetching token")));
      console.error("Error fetching token:", error);
    }
  };

  return token ? (
    <div>
      <b>{token}</b>{" "}
      <ModifyButton
        style={{ width: "90px", marginLeft: "5px" }}
        onClick={async () => {
          await navigator.clipboard.writeText(token);
          message.success(t("Token copied to clipboard!"));
        }}
      >
        {t("Copy")}
      </ModifyButton>
    </div>
  ) : (
    <div style={{ width: "130px", display: "flex", alignItems: "center" }}>
      <ModifyButton style={{ width: "100px" }} onClick={fetchToken}>
        {t("API Token")}
      </ModifyButton>
      <div style={{ margin: "auto" }}>
        <HelpQuestionCircle
          text={t("API token is reset every time you fetch it.")}
        />
      </div>
    </div>
  );
};

export default TokenButton;
