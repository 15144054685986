import { Button, Form, Input, message, Select } from "antd";
import {
  dataConnectionDetailsMap,
  displayError,
  getOnlyNeededKeys,
} from "../Helpers";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchData from "../hooks/useFetchData";
import * as apiService from "../services/apiServices";
import { useNavigate } from "react-router-dom";

const DataConnectionAddForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [dataConnections] = useFetchData(
    apiService.getDataConnectionOptions,
    {},
  );
  const [dataConnectionNames, setDataConnectionNames] = useState();
  const [selectedIntegration, setSelectedIntegration] = useState<string>("");
  const [items, setItems] = useState<[string, string][]>([]);

  useEffect(() => {
    if (dataConnections)
      setDataConnectionNames(
        dataConnections.map((item: string) => ({
          value: item,
          label: item,
        })),
      );
  }, [dataConnections]);

  const handleSelect = async (value: string) => {
    setSelectedIntegration(value);
    const config: Map<string, string> =
      await apiService.getDataConnectionTemplate(value);
    setItems(getOnlyNeededKeys(config));
  };
  const handleSave = async (values: { [key: string]: string }) => {
    try {
      delete values["dataConnectionName"];
      await apiService.createDataConnection(selectedIntegration, values);
      message.success(t("Integration added successfully"));
      navigate("/data-connections");
    } catch (error) {
      message.error(t(displayError(error, "Error updating API parameters.")));
    }
  };

  return (
    <Form onFinish={handleSave} requiredMark={false}>
      <Form.Item
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 12 }}
        label={t("Select integration")}
        name="dataConnectionName"
      >
        <Select options={dataConnectionNames} onChange={handleSelect} />
      </Form.Item>
      {selectedIntegration && (
        <>
          {items.map(([key, value]) => (
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label={t(dataConnectionDetailsMap[key])}
              name={key}
              rules={[{ required: true, message: "" }]}
            >
              <Input defaultValue={value} value={value} />
            </Form.Item>
          ))}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t("Add")}
            </Button>
          </Form.Item>
        </>
      )}
    </Form>
  );
};

export default DataConnectionAddForm;
