import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isLoggedin } from "../services/apiServices";
import { message } from "antd";
import { useTranslation } from "react-i18next";

const useRedirectIfLoggedOut = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    const verifyLogout = async () => {
      const isLoggedIn = await isLoggedin();
      if (!isLoggedIn) {
        message.info(t("Logged out"));
        localStorage.clear();
        navigate("/login");
      }
    };

    verifyLogout();
  }, [navigate, t]);
};

export default useRedirectIfLoggedOut;
