import React, { useState } from "react";
import {
  Button,
  Card,
  Descriptions,
  DescriptionsProps,
  Divider,
  Form,
  Input,
  message,
  Modal,
} from "antd";
import { CustomerProps, EditModeProps, User } from "../types";
import { adjustCronValue, displayError, estonianLocale } from "../Helpers";
import * as apiService from "../services/apiServices";
import { useNavigate } from "react-router-dom";
import useFetchData from "../hooks/useFetchData";
import Cron from "react-js-cron";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import UserUpdateForm from "../forms/UserUpdate";
import { useTranslation } from "react-i18next";
import {
  CustomCard,
  DeleteButton,
  HelpQuestionCircle,
  ModifyButton,
} from "./styledComponents";
import TimezoneSelector from "./TimeZoneSelector";
import TokenButton from "./TokenButton";

export const CustomerCard: React.FC<CustomerProps> = ({ customer }) => {
  const { t } = useTranslation();
  const handleTimezoneUpdate = async (newTimezone: string) => {
    try {
      await apiService.updateCustomer(customer.id, {
        timezone: newTimezone,
        name: customer.name,
      });
      message.success(t("Timezone changed"));
      localStorage.setItem(
        "customer",
        JSON.stringify({ ...customer, timezone: newTimezone }),
      );
    } catch (error) {
      message.error(t(displayError(error, "Error updating timezone")));
    }
  };
  return (
    <CustomCard>
      <h1>{customer.name}</h1>
      <Divider></Divider>
      <h3>{customer.email}</h3>
      <Divider></Divider>
      <h3>{t("Company's Timezone")}</h3>
      <h3>
        <TimezoneSelector
          initialTimezone={customer.timezone}
          onUpdate={handleTimezoneUpdate}
        />
      </h3>
    </CustomCard>
  );
};

export const EmailCustomerCard: React.FC<CustomerProps> = ({ customer }) => {
  const [cronValue, setCronValue] = useState(customer.emails_cron_schedule);
  const { t, i18n } = useTranslation();
  const updateCron = () => {
    const fetchData = async () => {
      try {
        await apiService.updateCustomer(customer.id, {
          emails_cron_schedule: adjustCronValue(cronValue),
          name: customer.name,
        });
        message.success(t("Schedule changed"));
        const updatedCustomer = {
          ...customer,
          emails_cron_schedule: adjustCronValue(cronValue),
        };
        localStorage.setItem("customer", JSON.stringify(updatedCustomer));
      } catch (error) {
        message.error(t(displayError(error, "Error updating schedule")));
      }
    };
    fetchData();
  };

  return (
    <CustomCard>
      <h1>
        Email{" "}
        <HelpQuestionCircle
          text={t(
            "If there are errors during the operation of the service, they will be forwarded to an email whose sending interval is changeable.",
          )}
        />
      </h1>
      <Cron
        value={cronValue}
        clearButton={false}
        setValue={setCronValue}
        allowedDropdowns={[
          "period",
          "months",
          "month-days",
          "week-days",
          "hours",
        ]}
        allowedPeriods={["year", "month", "week", "day", "hour"]}
        locale={i18n.language === "ee" ? estonianLocale : undefined}
        className="cron"
      />
      <Button size="large" onClick={updateCron}>
        {t("Update email settings")}
      </Button>
    </CustomCard>
  );
};

export const UsersCard: React.FC<EditModeProps> = ({ editMode }) => {
  const [users, , fetchData] = useFetchData(apiService.getUsers, {});
  const [currentUser] = useFetchData(apiService.getCurrentUser, {});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const showModal = () => {
    setIsModalVisible(true);
  };

  const onFinish = async (values: any) => {
    setIsModalVisible(false);
    try {
      await apiService.sendRegistrationEmail(values.email);
      message.success(t("Email sent"));
    } catch (error) {
      message.error(t(displayError(error, "Error sending email")));
    }
  };

  const handleEditClick = () => {
    navigate("/company/edit");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const myUserItems: DescriptionsProps["items"] = [
    {
      key: "2",
      label: "Email",
      children: currentUser.email,
    },
    {
      key: "3",
      label: t("First name"),
      children: currentUser.first_name,
    },
    {
      key: "4",
      label: t("Last name"),
      children: currentUser.last_name,
    },
  ];

  const handleUserDelete = (user_id: string) => {
    Modal.confirm({
      title: t("Are you sure you want to delete the user?"),

      onOk: async () => {
        try {
          await apiService.deleteUser(user_id);
          message.success(t("User deleted"));
          fetchData();
        } catch (error) {
          message.error(t(displayError(error, "Error deleting user")));
        }
      },

      onCancel() {},
    });
  };
  if (!currentUser || Array.isArray(currentUser)) return null;
  return (
    <div>
      <Divider />

      <h1>{t("My data")}</h1>
      <Card bodyStyle={{ padding: 0, display: "flex" }}>
        <div style={{ flex: "98%", margin: "24px" }}>
          {editMode ? (
            <UserUpdateForm currentUser={currentUser} />
          ) : (
            <div>
              <Descriptions
                layout="horizontal"
                labelStyle={{ width: "20%", minWidth: "120px" }}
                style={{
                  width: "100%",
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }} // Adjusted to 100% of the parent div
                contentStyle={{
                  width: "100%",
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
                column={1}
                items={myUserItems}
              />
              {currentUser.has_token ? <TokenButton /> : null}
            </div>
          )}
        </div>
        <div style={{ flex: "2%", display: "flex", alignItems: "stretch" }}>
          {!editMode && (
            <ModifyButton
              type="default"
              onClick={handleEditClick}
              style={{
                borderBottomLeftRadius: 0,
                borderTopLeftRadius: 0,
              }}
            >
              <EditOutlined />
            </ModifyButton>
          )}
        </div>
      </Card>

      <div>
        <h1>{t("Users")}</h1>
        {users.map((user: User) => (
          <Card
            key={user.id}
            style={{ marginBottom: "20px" }}
            bodyStyle={{ padding: "0", display: "flex" }}
          >
            <div style={{ flex: "98%", margin: "24px" }}>
              <Descriptions
                layout="horizontal"
                labelStyle={{ width: "20%", minWidth: "120px" }}
                style={{
                  width: "100%",
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }} // Adjusted to 100% of the parent div
                contentStyle={{
                  width: "100%",
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
                column={1}
                items={[
                  {
                    key: "1",
                    label: t("Username"),
                    children: user.username,
                  },
                  {
                    key: "2",
                    label: "Email",
                    children: user.email,
                  },
                  {
                    key: "3",
                    label: t("Name"),
                    children: user.first_name + " " + user.last_name,
                  },
                ]}
              />
            </div>
            <div style={{ flex: "2%", display: "flex", alignItems: "stretch" }}>
              <DeleteButton
                onClick={() => handleUserDelete(user.id)}
                type="default"
                style={{
                  borderBottomLeftRadius: 0,
                  borderTopLeftRadius: 0,
                }}
                danger
              >
                <DeleteOutlined />
              </DeleteButton>
            </div>
          </Card>
        ))}
        <Card bodyStyle={{ padding: "0", display: "flex" }}>
          <ModifyButton onClick={showModal}>
            <PlusOutlined />
          </ModifyButton>
        </Card>
        <Modal
          title={t("New user invite")}
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              {t("Cancel")}
            </Button>,
            <Button
              key="submit"
              type="primary"
              form="invite_user"
              htmlType="submit"
            >
              {t("Invite")}
            </Button>,
          ]}
        >
          <Form
            id="invite_user"
            name="invite_user"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: t("Please enter new user email"),
                },
              ]}
            >
              <Input placeholder={t("Enter new user email")} />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
};
