import React from "react";
import { Card, Layout } from "antd";
import DataConnectionAddForm from "../forms/DataConnectionAdd";

const DataConnectionAddPage = () => {
  return (
    <Layout>
      <Card>
        <DataConnectionAddForm />
      </Card>
    </Layout>
  );
};

export default DataConnectionAddPage;
