import React from "react";
import { Alert, ConfigProvider, message, Modal, Switch } from "antd";
import * as apiService from "../services/apiServices";
import { Service } from "../types";
import { useTranslation } from "react-i18next";
import { displayError } from "../Helpers";

type SwitchServiceProps = {
  service: Service;
  setService: React.Dispatch<React.SetStateAction<Service>>;
};

const SwitchService: React.FC<SwitchServiceProps> = ({
  service,
  setService,
}) => {
  const { t } = useTranslation();
  const serviceStatus = service.enabled;
  const updateCustomerServiceStatus = (checked: boolean) => {
    Modal.confirm({
      title: checked
        ? t("Are you sure you want to enable this service")
        : t("Are you sure you want to disable this service"),

      onOk: async () => {
        const fetchData = async () => {
          try {
            const cron = service.cron || "0 */6 * * *";
            await apiService.updateCustomerService(
              service.id,
              service.settings,
              checked,
              cron,
            );
            message.success(
              checked ? t("Service is enabled") : t("Service is disabled"),
            );
            const updatedService = { ...service, enabled: !service.enabled };
            setService(updatedService);
          } catch (e) {
            message.error(
              t(displayError(e, "Error enabling/disabling service")),
            );
          }
        };
        fetchData();
      },
      onCancel() {
        // Do nothing on cancel
      },
    });
  };

  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Alert: {
              colorError: "#f5f5f500",
              colorErrorBorder: "#cacaca",
              colorErrorBg: "#f5f5f5",
              colorText: serviceStatus ? "rgba(0,0,0,.85)" : "rgba(0,0,0,.25)",
            },
          },
        }}
      >
        <Alert
          style={{ height: "4rem", marginTop: "1rem" }}
          message={
            <div style={{ marginTop: "0.2rem" }}>
              {serviceStatus
                ? t("Service is enabled")
                : t("Service is disabled")}
              <Switch
                onChange={updateCustomerServiceStatus}
                style={{ float: "right" }}
                checked={serviceStatus}
              />
            </div>
          }
          type={serviceStatus ? "info" : "error"}
        />
      </ConfigProvider>
    </div>
  );
};

export default SwitchService;
