import axios from "axios";
import { getCurrentCustomer } from "../Helpers";
import Cookies from "js-cookie";

const BASE_URL = window.appConfig.REACT_APP_BACKEND_URL;

const axiosInstance = axios.create({
  withCredentials: true,
});

axiosInstance.interceptors.request.use(function (config) {
  const token = Cookies.get("csrftoken");
  if (
    token &&
    !config.headers["X-CSRFToken"] &&
    config.method &&
    !["get", "head", "options"].includes(config.method)
  ) {
    config.headers["X-CSRFToken"] = token;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const cookie = await getCSRFToken();
        originalRequest.headers["X-CSRFToken"] = cookie;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        console.error("Failed to refresh CSRF token:", refreshError);
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  },
);

export const login = async (
  username: string,
  password: string,
  remember: boolean,
) => {
  try {
    const response = await axiosInstance.post(BASE_URL + "login", {
      username,
      password,
      remember,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getCSRFToken = async () => {
  try {
    const response = await axios.get(BASE_URL + "csrf-cookie");
    return response.data.csrfToken;
  } catch (error) {
    console.error("Failed to fetch CSRF token:", error);
    throw error;
  }
};

export const logout = async () => {
  const response = await axiosInstance.post(BASE_URL + "logout", {});
  return response.data;
};

export const getUserServices = async (filters = {}) => {
  const current_customer = await getCurrentCustomer();
  if (!current_customer) {
    return [];
  }
  const filtersWithCustomerId = {
    ...filters,
    customer__id: current_customer.id,
  };
  const queryParams = new URLSearchParams(filtersWithCustomerId).toString();
  const url = `${BASE_URL}customer-services/?${queryParams}`;

  const response = await axiosInstance.get(url);
  return response.data.results;
};

export const createUserServices = async (id: string, cronValue: string) => {
  const current_customer = await getCurrentCustomer();
  if (!current_customer) {
    return [];
  }
  const url = `${BASE_URL}customer-services/`;
  const response = await axiosInstance.post(url, {
    customer: current_customer.id,
    service: id,
    enabled: false,
    status: "idle",
    settings: {},
    cron: cronValue,
  });
  return response.data.response;
};

export const getUserServicesOptions = async () => {
  const response = await axiosInstance.get(
    BASE_URL + "customer-services/options/",
  );
  return response.data.results;
};

export const updateCustomerService = async (
  service_id: string,
  settings: any,
  enabled: boolean,
  cron: string | null,
) => {
  const current_customer = await getCurrentCustomer();
  if (!current_customer) {
    return [];
  }
  const data: {
    settings: any;
    customer: string;
    enabled: boolean;
    id: string;
    cron?: string;
  } = {
    settings: settings,
    customer: current_customer.id,
    enabled: enabled,
    id: service_id,
  };

  if (cron) {
    data.cron = cron;
  }
  const url = `${BASE_URL}customer-services/${service_id}/`;
  const response = await axiosInstance.patch(url, data);
  return response.data.response;
};

export const getUserServiceDones = async (filters = {}) => {
  const current_customer = await getCurrentCustomer();
  if (!current_customer) {
    return [];
  }
  const filtersWithCustomerId = {
    ...filters,
    customer__id: current_customer.id,
  };
  const queryParams = new URLSearchParams(filtersWithCustomerId).toString();
  const url = `${BASE_URL}service-dones/?${queryParams}`;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    return [];
  }
};

export const countServiceDones = async (filters = { service_id: "" }) => {
  const service_id = filters.service_id;
  const url = `${BASE_URL}service-dones/${service_id}/count`;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    return [];
  }
};

export const runCustomerService = async (service_id: string) => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}customer-services/${service_id}/run/`,
    );
    return response.data;
  } catch (error) {
    console.error("Error running customer service");
  }
};

export const deleteServiceDone = async (
  service_id: string,
  service_done_id: string,
) => {
  return await axiosInstance.delete(
    `${BASE_URL}service-dones/${service_done_id}/`,
  );
};

export const getCustomers = async () => {
  const response = await axiosInstance.get(BASE_URL + "customers/");
  return response.data.results;
};

export const createCustomer = async (data: {}) => {
  const response = await axiosInstance.post(BASE_URL + "customers/", {
    ...data,
  });
  return response.data;
};

export const updateCustomer = async (
  customer_id: string,
  update_fields: {},
) => {
  const response = await axiosInstance.put(
    `${BASE_URL}customers/${customer_id}/`,
    update_fields,
  );
  return response.data;
};

export const getUsers = async (filters = {}) => {
  try {
    const response = await axiosInstance.get(BASE_URL + "users/");
    return response.data.results;
  } catch (error) {
    return [];
  }
};

export const getCurrentUser = async (filters = {}) => {
  try {
    const response = await axiosInstance.get(BASE_URL + "users/self", {});
    return response.data;
  } catch (error) {
    return [];
  }
};

export const deleteUser = async (user_id: string) => {
  return await axiosInstance.delete(`${BASE_URL}users/${user_id}/`);
};

export const sendRegistrationEmail = async (email: string) => {
  const response = await axiosInstance.post(BASE_URL + "users/send-invite/", {
    email: email,
  });
  return response.data;
};

export const confirmAccount = async (confirmationToken: string) => {
  const response = await axiosInstance.post(
    BASE_URL + "users/confirm-registration/",
    {
      token: confirmationToken,
    },
  );
  return response.data;
};

export const register = async (
  password: string,
  first_name: string,
  last_name: string,
  language: string,
  inv_token?: string,
  email?: string,
  phone?: string,
) => {
  const response = await axiosInstance.post(BASE_URL + "users/", {
    inv_token: inv_token,
    password: password,
    first_name: first_name,
    last_name: last_name,
    language: language,
    email: email,
    phone: phone,
    username: email,
  });
  return response.data;
};

export const getUserDataConnections = async (filters = {}) => {
  const current_customer = await getCurrentCustomer();
  if (!current_customer) {
    return [];
  }
  const filtersWithCustomerId = {
    ...filters,
    customer__id: current_customer.id,
  };
  const queryParams = new URLSearchParams(filtersWithCustomerId).toString();
  const url = `${BASE_URL}data-connections/?${queryParams}`;
  const response = await axiosInstance.get(url);
  return response.data.results;
};

export const getTaxesRelations = async (filters: {}) => {
  const current_customer = await getCurrentCustomer();
  if (!current_customer) {
    return [];
  }
  const filtersWithCustomerId = {
    ...filters,
    customer__id: current_customer.id,
  };
  const queryParams = new URLSearchParams(filtersWithCustomerId).toString();
  const url = `${BASE_URL}taxes-relations/?${queryParams}`;
  const response = await axiosInstance.get(url);
  return response.data;
};

export const getTaxes = async (filters: {}) => {
  const current_customer = await getCurrentCustomer();
  if (!current_customer) {
    return [];
  }
  const filtersWithCustomerId = {
    ...filters,
    customer__id: current_customer.id,
  };
  const queryParams = new URLSearchParams(filtersWithCustomerId).toString();
  const url = `${BASE_URL}taxes/?${queryParams}`;
  const response = await axiosInstance.get(url);
  return response.data;
};

export const updateTaxesRelation = async (
  tax1Id: string,
  tax2Id: string,
  taxRelationId?: string,
) => {
  const current_customer = await getCurrentCustomer();
  if (!current_customer) {
    throw new Error("No current customer");
  }

  if (taxRelationId) {
    const response = await axiosInstance.put(
      `${BASE_URL}taxes-relations/${taxRelationId}/`,
      {
        customer: current_customer.id,
        tax1_id: tax1Id,
        tax2_id: tax2Id,
      },
    );
    return response.data;
  } else {
    const response = await axiosInstance.post(`${BASE_URL}taxes-relations/`, {
      customer: current_customer.id,
      tax1_id: tax1Id,
      tax2_id: tax2Id,
    });
    return response.data;
  }
};

export const deleteTaxRelation = async (taxRelationId: string) => {
  return await axiosInstance.delete(
    `${BASE_URL}taxes-relations/${taxRelationId}/`,
  );
};

export const getDataConnectionOptions = async () => {
  const response = await axiosInstance.get(
    BASE_URL + "data-connections/options/",
  );
  return response.data.results;
};

export const getDataConnectionTemplate = async (name: string) => {
  const filters = {
    name: name,
  };
  const queryParams = new URLSearchParams(filters).toString();
  const url = `${BASE_URL}data-connections/template/?${queryParams}`;
  const response = await axiosInstance.get(url);
  return response.data;
};

export const createDataConnection = async (name: string, config: {}) => {
  const current_customer = await getCurrentCustomer();
  if (!current_customer) {
    return [];
  }
  const url = `${BASE_URL}data-connections/`;
  const response = await axiosInstance.post(url, {
    customer: current_customer.id,
    name: name,
    config: config,
  });
  return response.data.response;
};

export const updateDataConnection = async (
  data_connection_id: string,
  config: {},
  name: string,
) => {
  const current_customer = await getCurrentCustomer();
  if (!current_customer) {
    return [];
  }
  const url = `${BASE_URL}data-connections/${data_connection_id}/`;
  const response = await axiosInstance.put(url, {
    config: config,
    customer: current_customer.id,
    id: data_connection_id,
    name: name,
  });
  return response.data.response;
};

export const deleteDataConnection = async (id: string) => {
  const current_customer = await getCurrentCustomer();
  if (!current_customer) {
    return [];
  }
  const filters = {
    customer__id: current_customer.id,
  };
  const queryParams = new URLSearchParams(filters).toString();
  const url = `${BASE_URL}data-connections/${id}/?${queryParams}`;
  const response = await axiosInstance.delete(url);
  return response.data.response;
};

export const updateUser = async (update_fields: {}) => {
  const response = await axiosInstance.patch(BASE_URL + "users/self/", {
    update_fields: update_fields,
  });
  return response.data;
};

export const resetPassword = async (email: string) => {
  const response = await axiosInstance.post(
    BASE_URL + "users/reset-password/",
    { email: email },
  );
  return response.data;
};

export const resetPasswordConfirm = async (
  password: string,
  reset_token: string,
) => {
  const response = await axiosInstance.post(
    BASE_URL + "users/reset-password-confirm/",
    {
      password: password,
      reset_token: reset_token,
    },
  );
  return response.data;
};

export const getUserFeedback = async (filters = {}) => {
  const current_customer = await getCurrentCustomer();
  if (!current_customer) {
    return [];
  }
  const filtersWithCustomerId = {
    ...filters,
    customer__id: current_customer.id,
  };
  const queryParams = new URLSearchParams(filtersWithCustomerId).toString();
  const url = `${BASE_URL}feedback/?${queryParams}`;
  const response = await axiosInstance.get(url);
  return response.data.results;
};

export const createUserFeedback = async (
  title: string,
  text: string,
  serviceId: string,
) => {
  const current_customer = await getCurrentCustomer();
  if (!current_customer) {
    return [];
  }
  const url = `${BASE_URL}feedback/`;
  const response = await axiosInstance.post(url, {
    title: title,
    text: text,
    customer_service: serviceId,
    customer: current_customer.id,
    status: "opened",
    entries: [{ text: text }],
  });
  return response.data.response;
};

export const createFeedbackEntry = async (text: string, feedbackId: string) => {
  const response = await axiosInstance.post(
    `${BASE_URL}feedback/${feedbackId}/entry/`,
    {
      text: text,
    },
  );
  return response.data.response;
};

export const markFeedbackClosed = async (feedbackId: string) => {
  const response = await axiosInstance.patch(
    `${BASE_URL}feedback/${feedbackId}/`,
    {
      status: "closed",
    },
  );
  return response.data.response;
};

export const isLoggedin = async () => {
  try {
    await axiosInstance.get(BASE_URL + "users/self/");
    return true;
  } catch (error) {
    return false;
  }
};

export const checkUserInvite = async (inv_token: string) => {
  try {
    const response = await axiosInstance.post(
      BASE_URL + "users/check-invite-token/",
      {
        inv_token: inv_token,
      },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const checkPasswordReset = async (reset_token: string) => {
  try {
    const response = await axiosInstance.post(
      BASE_URL + "users/check-reset-token/",
      {
        reset_token: reset_token,
      },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getToken = async () => {
  const response = await axiosInstance.post(`${BASE_URL}users/token/`);
  return response.data;
};
