import { Button, Form, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import { DataConnectionProps } from "../types";
import {
  dataConnectionDetailsMap,
  dataConnectionPrivateFields,
  displayError,
  getOnlyNeededKeys,
} from "../Helpers";
import {
  EditOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import * as apiService from "../services/apiServices";
import { useTranslation } from "react-i18next";
import { DeleteButton } from "../components/styledComponents";
import { useNavigate } from "react-router-dom";

const DataConnectionForm: React.FC<DataConnectionProps> = ({
  dataConnection,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const items: [string, string][] = getOnlyNeededKeys(dataConnection.config);
  const [isSaving, setIsSaving] = useState(false);
  const [parameters, setParameters] = useState(dataConnection.config);

  useEffect(() => {
    if (dataConnection.config) setParameters(dataConnection.config);
  }, [dataConnection]);

  const handleSave = async (values: {}) => {
    if (isSaving) {
      return;
    }
    setIsSaving(true);
    try {
      await apiService.updateDataConnection(
        dataConnection.id,
        values,
        dataConnection.name,
      );
      message.success(t("Integration parameters updated"));
    } catch (error) {
      message.error(t(displayError(error, "Error updating API parameters.")));
    } finally {
      setIsSaving(false);
    }
  };

  const handleDelete = async () => {
    try {
      await apiService.deleteDataConnection(dataConnection.id);
      message.success(t("Integration deleted"));
      navigate("/data-connections");
    } catch (error) {
      message.error(
        t(
          displayError(
            error,
            "An error occurred while deleting data connection.",
          ),
        ),
      );
    }
  };

  return (
    <Form onFinish={handleSave} initialValues={parameters} requiredMark={false}>
      {items.map(([key, value]) => (
        <Form.Item
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          label={t(dataConnectionDetailsMap[key])}
          name={key}
          rules={[{ required: true, message: "" }]}
        >
          {dataConnectionPrivateFields.includes(key) ? (
            <Input.Password
              defaultValue={value}
              value={value}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          ) : (
            <Input defaultValue={value} value={value} />
          )}
        </Form.Item>
      ))}
      <Form.Item>
        <Button
          shape="round"
          type="primary"
          icon={<EditOutlined />}
          disabled={isSaving}
          htmlType="submit"
        >
          {t("Update")}
        </Button>
        <DeleteButton
          shape="round"
          type="primary"
          onClick={handleDelete}
          style={{
            float: "right",
            backgroundColor: "red",
            width: "auto",
            color: "white",
          }}
        >
          {t("Delete")}
        </DeleteButton>
      </Form.Item>
    </Form>
  );
};

export default DataConnectionForm;
