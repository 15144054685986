import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import {
  CustomerCard,
  EmailCustomerCard,
  UsersCard,
} from "../components/customerCard";
import { Customer, EditModeProps } from "../types";
import useMobileDetect from "../hooks/useMobileDetect";
import { getCurrentCustomer, processError } from "../Helpers";
import { useNavigate } from "react-router-dom";
import CompanyTaxes from "../components/companyTaxes";

const CompanyDetails: React.FC<EditModeProps> = ({ editMode }) => {
  const isSmaller1024 = useMobileDetect(1024);
  const navigate = useNavigate();
  const [currentCustomer, setCurrentCustomer] = useState<Customer | null>(null);

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const customer = await getCurrentCustomer();
        if (customer) {
          setCurrentCustomer(customer);
        }
      } catch (error) {
        navigate(processError(error));
      }
    };

    fetchCustomer();
  }, [navigate]);

  if (!currentCustomer) {
    navigate("/404");
    return null;
  }

  return (
    <Row gutter={[isSmaller1024 ? 0 : 30, 30]} style={{ width: "100%" }}>
      <Col xl={12} xs={24}>
        <CustomerCard customer={currentCustomer} />
      </Col>

      <Col xl={12} xs={24}>
        <EmailCustomerCard customer={currentCustomer} />
      </Col>
      <Col span={24}>
        <CompanyTaxes />
      </Col>
      <Col span={24}>
        <UsersCard editMode={editMode} />
      </Col>
    </Row>
  );
};

export default CompanyDetails;
