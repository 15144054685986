import React from "react";
import { Layout } from "antd";

const { Header } = Layout;
const MainHeader = () => {
  return (
    <Header className="header">
      <div style={{ float: "right" }}></div>
    </Header>
  );
};

export default MainHeader;
