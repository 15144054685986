import React, { useEffect, useState } from "react";
import * as apiService from "../services/apiServices";
import { Button, Tabs } from "antd";

import { Service } from "../types";
import useFetchData from "../hooks/useFetchData";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ServiceTab from "../components/ServiceTab";
import { PlusOutlined } from "@ant-design/icons";

const MyServicePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [services] = useFetchData<Service[]>(apiService.getUserServices, {});
  const [currentTab] = useState("active");
  const activeServices = services.filter((service: Service) => service.enabled);
  const inactiveServices = services.filter(
    (service: Service) => !service.enabled,
  );

  useEffect(() => {
    if (!currentTab || !["active", "inactive"].includes(currentTab)) {
      navigate("/404");
    }
  }, [currentTab, navigate]);

  const items = [
    {
      key: "active",
      label: t("Active"),
      children: <ServiceTab services={activeServices} />,
    },
    {
      key: "inactive",
      label: t("Inactive"),
      children: <ServiceTab services={inactiveServices} />,
    },
  ];

  return (
    <div className="dashboard-services">
      <h2>{t("My Services")}</h2>
      <Button
        shape="round"
        type="primary"
        icon={<PlusOutlined />}
        style={{ float: "right" }}
        onClick={() => navigate("/services/my-services/add")}
      >
        {t("Add")}
      </Button>
      <Tabs defaultActiveKey={currentTab} items={items} />
    </div>
  );
};

export default MyServicePage;
