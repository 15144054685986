import React from "react";
import { Divider, Form, Input, message } from "antd";
import { CloseOutlined, LockOutlined } from "@ant-design/icons";
import * as apiService from "../services/apiServices";
import { UpdateFields, UserUpdateFormProps } from "../types";
import {
  confirmPasswordValidationRule,
  displayError,
  passwordValidationRule,
} from "../Helpers";
import { useTranslation } from "react-i18next";
import { DeleteButton, ModifyButton } from "../components/styledComponents";
import { useNavigate } from "react-router-dom";

const UserUpdateForm: React.FC<UserUpdateFormProps> = ({ currentUser }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const onUserUpdateFinish = async (values: any) => {
    const all_values = {
      old_password: values.oldPassword,
      password: values.password,
      email: values.email,
      first_name: values.firstName,
      last_name: values.lastName,
    };

    const update_fields = Object.entries(all_values).reduce<UpdateFields>(
      (acc, [key, value]) => {
        if (value) {
          acc[key] = value;
        }
        return acc;
      },
      {},
    );
    try {
      await apiService.updateUser(update_fields);
      message.success(t("Successfully updated"));
    } catch (error: unknown) {
      message.error(t(displayError(error, "Error updating customer"))); // TODO translate
    }
  };
  const handleUserEditCancel = () => {
    navigate("/company");
  };

  return (
    <Form
      initialValues={{
        firstName: currentUser.first_name,
        lastName: currentUser.last_name,
        email: currentUser.email,
      }}
      name="registration"
      size="large"
      layout="vertical"
      className="registrationForm"
      onFinish={onUserUpdateFinish}
      form={form}
    >
      <h3>{t("Update your data")}</h3>
      <Form.Item label={t("First name")} name="firstName">
        <Input
          defaultValue={currentUser.first_name}
          autoComplete="off"
          placeholder={t("First name")}
        />
      </Form.Item>

      <Form.Item label={t("Last name")} name="lastName">
        <Input
          defaultValue={currentUser.last_name}
          autoComplete="off"
          placeholder={t("Last name")}
        />
      </Form.Item>
      <Form.Item label="Email" name="email">
        <Input
          defaultValue={currentUser.email}
          autoComplete="off"
          placeholder="Email"
        />
      </Form.Item>
      <Divider />
      <Form.Item
        label={t("Old password")}
        name="oldPassword"
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value && getFieldValue("password")) {
                return Promise.reject(
                  new Error(t("Please enter your old password")),
                );
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input.Password
          autoComplete="new-password"
          prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder={t("Old password")}
        />
      </Form.Item>

      <Form.Item
        label={t("New password")}
        name="password"
        rules={[passwordValidationRule(t)]}
      >
        <Input.Password
          autoComplete="new-password"
          prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder={t("New password")}
        />
      </Form.Item>

      <Form.Item
        label={t("Confirm password")}
        name="confirm"
        dependencies={["password"]} // This will trigger revalidation when the password field changes
        rules={[confirmPasswordValidationRule(form.getFieldValue, t)]}
      >
        <Input.Password
          autoComplete="new-password"
          prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder={t("Confirm password")}
        />
      </Form.Item>

      <Form.Item>
        <ModifyButton
          style={{ maxWidth: "20rem", width: "100%" }}
          htmlType="submit"
        >
          {t("Update")}
        </ModifyButton>
      </Form.Item>
      <DeleteButton
        style={{ maxWidth: "20rem" }}
        type="default"
        onClick={handleUserEditCancel}
        danger
      >
        <CloseOutlined />
      </DeleteButton>
    </Form>
  );
};

export default UserUpdateForm;
