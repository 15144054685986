import React, { useEffect } from "react";
import { Divider, Form, Input, message, Typography } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import * as apiService from "../services/apiServices";
import { useTranslation } from "react-i18next";
import {
  confirmPasswordValidationRule,
  displayError,
  passwordValidationRule,
} from "../Helpers";
import { ModifyButton, SubmitButton } from "../components/styledComponents";

const { Text, Title } = Typography;

const InviteBasedRegistrationForm = () => {
  const { registrationToken } = useParams();
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      if (registrationToken) {
        try {
          await apiService.checkUserInvite(registrationToken);
        } catch (error) {
          message.error(
            t(displayError(error, "Error with registration token")),
          );
          navigate("/register");
        }
      }
    };
    fetchData();
  }, [registrationToken, navigate, t]);
  const onFinish = async (values: any) => {
    if (values.password !== values.confirm) {
      return;
    }

    try {
      await apiService.register(
        values.password,
        values.firstName,
        values.lastName,
        i18n.language,
        registrationToken,
        values.email,
        values.phone,
      );
      if (registrationToken) {
        message.success(t("Account created"));
      } else {
        message.success(
          t("Account created. Please check your email for activation link."),
        );
      }
      navigate("/login");
    } catch (error) {
      message.error(t(displayError(error, "Error creating account")));
    }
  };

  return (
    <Form
      name="registration"
      size="large"
      className="loginForm"
      form={form}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
    >
      <Title>{t("Register")}</Title>
      <div style={{ marginBottom: "10px" }}>
        <Text type="secondary">{t("Mandatory")}</Text>
      </div>
      {!registrationToken && (
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: t("Please enter your email"),
            },
          ]}
        >
          <Input type="email" autoComplete="email" placeholder={t("Email")} />
        </Form.Item>
      )}
      <Form.Item
        name="firstName"
        rules={[
          {
            required: true,
            message: t("Please enter your first name"),
          },
        ]}
      >
        <Input autoComplete="given-name" placeholder={t("First name")} />
      </Form.Item>

      <Form.Item
        name="lastName"
        rules={[
          {
            required: true,
            message: t("Please enter your last name"),
          },
        ]}
      >
        <Input autoComplete="family-name" placeholder={t("Last name")} />
      </Form.Item>

      <Form.Item
        name="password"
        hasFeedback
        rules={[passwordValidationRule(t)]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder={t("Password")}
        />
      </Form.Item>

      <Form.Item
        name="confirm"
        dependencies={["password"]}
        hasFeedback
        rules={[confirmPasswordValidationRule(form.getFieldValue, t)]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder={t("Confirm password")}
        />
      </Form.Item>
      <Divider />
      <div style={{ marginBottom: "10px" }}>
        <Text type="secondary">{t("Optional")}</Text>
      </div>
      <Form.Item
        name="phone"
        rules={[
          {
            message: t("Please enter your phone number"),
          },
        ]}
      >
        <Input type="tel" autoComplete="tel" placeholder={t("Phone number")} />
      </Form.Item>
      <Form.Item>
        <SubmitButton>{t("Register")}</SubmitButton>
      </Form.Item>
      <Form.Item>
        <a href="login">
          <ModifyButton>{t("Return to login")}</ModifyButton>
        </a>
      </Form.Item>
    </Form>
  );
};

export default InviteBasedRegistrationForm;
