import { useCallback, useEffect, useMemo, useState } from "react";

type FetchFunction<T> = (...args: any[]) => Promise<T[]>;

const useFetchData = <T,>(fetchFn: FetchFunction<T>, params: object) => {
  const [data, setData] = useState<T[]>([]);
  const [error, setError] = useState<any>("");

  const serializedParams = useMemo(() => JSON.stringify(params), [params]);

  const fetchData = useCallback(async () => {
    try {
      // Parse the serialized params back to object
      const parsedParams = JSON.parse(serializedParams);
      const fetchedData = await fetchFn(parsedParams);
      setData(fetchedData);
    } catch (err) {
      setError(err);
    }
  }, [fetchFn, serializedParams]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((newData: T[]) => {
    setData(newData);
  }, []);

  return [data, error, fetchData, updateData];
};

export default useFetchData;
