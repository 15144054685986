import React from "react";
import useRedirectIfLoggedIn from "../hooks/useRedirectIfLoggedIn";
import "../css/login.css";
import "../css/styles.css";
import { LanguageSelect } from "./sidebar/sidebar";
import { ConfigProvider } from "antd";
import { Outlet } from "react-router-dom";
import front_image from "../images/unauthenticated-main-image.jpg";

const UnauthenticatedBaseLayout: React.FC = () => {
  useRedirectIfLoggedIn();

  return (
    <div>
      {/*Front image*/}
      <img src={front_image} alt={"customer service"} className="authSideBox" />
      <div className="authBox">
        <ConfigProvider
          theme={{
            components: {
              Menu: {
                groupTitleColor: "#f0f3f5",
              },
            },
          }}
        >
          <LanguageSelect className="no-auth-language-select" />
        </ConfigProvider>

        <h1 className="login-main-logo">ANDMEAADU</h1>
        <div className="authInner">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default UnauthenticatedBaseLayout;
