import React from "react";
import { Layout, Row, Empty } from "antd";
import { Service, ServiceTabProps } from "../types";
import ServiceCard from "./serviceCard";

const ServiceTab: React.FC<ServiceTabProps> = ({ services }) => {
  if (!services || services.length === 0) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  } else {
    return (
      <Layout>
        <div className="data-connections">
          <Row className="data-connections-row" gutter={[0, 40]}>
            {services.map((service: Service) => (
              <ServiceCard service={service} />
            ))}
          </Row>
        </div>
      </Layout>
    );
  }
};

export default ServiceTab;
