import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as apiService from "../services/apiServices";
import { message } from "antd";
import { displayError } from "../Helpers";
import { useTranslation } from "react-i18next";

const ActivateAccount = () => {
  const { token } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    const activateAccount = async () => {
      try {
        if (token) {
          await apiService.confirmAccount(token);
          message.success(t("Account activated"));
        }
      } catch (error) {
        message.error(
          t(displayError(error, "Unknown error confirming account")),
        );
      }
      navigate("/login");
    };
    activateAccount();
  }, [token, navigate, t]);

  return null;
};

export default ActivateAccount;
