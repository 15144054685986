import { Button, Form, Input, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { DataConnection, Service } from "../types";
import * as apiService from "../services/apiServices";
import useFetchData from "../hooks/useFetchData";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { displayError } from "../Helpers";

const FeedbackForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [services] = useFetchData<DataConnection[]>(
    apiService.getUserServices,
    {},
  );
  const [serviceNames, setConnectionNames] = useState();
  const { TextArea } = Input;

  useEffect(() => {
    if (services)
      setConnectionNames(
        services.map((connection: Service) => ({
          value: connection.id,
          label: t(connection.service_name),
        })),
      );
  }, [services, t]);

  const onFinish = async (values: any) => {
    try {
      await apiService.createUserFeedback(
        values.title,
        values.text,
        values.service_name,
      );

      message.success(t("Feedback added"));
      navigate("/feedback/opened");
    } catch (error) {
      message.error(
        t(displayError(error, "An error occurred while submitting feedback.")),
      );
    }
  };

  return (
    <Form onFinish={onFinish}>
      <Form.Item
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 12 }}
        label={t("Select related service")}
        name="service_name"
      >
        <Select options={serviceNames} />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 12 }}
        label={t("Title")}
        name="title"
      >
        <Input />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        label={t("Problem description")}
        name="text"
      >
        <TextArea rows={8} />
      </Form.Item>
      <Form.Item>
        <Button shape="round" type="primary" htmlType="submit">
          {t("Submit")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FeedbackForm;
