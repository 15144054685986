import React from "react";
import { Button, Result } from "antd";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotFoundPage = () => {
  const errorCodes = {
    "400":
      "Bad request - The request could not be understood by the server due to malformed syntax.",
    "401": "Unauthorized - The request requires user authentication.",
    "403":
      "Forbidden - The server understood the request, but is refusing to fulfill it.",
    "404":
      "Not Found - The server has not found anything matching the Request-URI.",
    "500":
      "Internal Server Error - The server encountered an unexpected condition which prevented it from fulfilling the request.",
    "502":
      "Bad Gateway - The server received an invalid response from the upstream server.",
    "503":
      "Service Unavailable - The server is currently unable to handle the request due to temporary overloading or maintenance of the server.",
  };
  const location = useLocation();
  const { t } = useTranslation();
  const path = location.pathname;
  const pageName = path.split("/")[1];
  const title = !pageName || !(pageName in errorCodes) ? "404" : pageName;

  const description = errorCodes[title as keyof typeof errorCodes];
  return (
    <Result
      className="custom-404-title"
      icon={<h1>ANDMEAADU</h1>}
      title={title}
      subTitle={t(description)}
      extra={
        <Button size="large" href="/" type="primary">
          {t("Return")}
        </Button>
      }
    />
  );
};

export default NotFoundPage;
