import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isLoggedin } from "../services/apiServices";

const useRedirectIfLoggedIn = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const verifyLogin = async () => {
      const isLoggedIn = await isLoggedin();
      if (isLoggedIn) {
        navigate("/services");
      }
    };

    verifyLogin();
  }, [navigate]);
};

export default useRedirectIfLoggedIn;
