import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Radio, RadioChangeEvent } from "antd";
import useMobileDetect from "../hooks/useMobileDetect";
import * as apiService from "../services/apiServices";
import useFetchData from "../hooks/useFetchData";
import { ServiceDoneCount } from "../types";
import { useTranslation } from "react-i18next";

type DateCount = {
  x: string;
  y: number;
};

const ServiceDonesGraph = ({ service_id }: { service_id: string }) => {
  const { t } = useTranslation();
  const isSmaller1024 = useMobileDetect(1024);
  const [chartData, setChartData] = useState<DateCount[]>([]);
  const [timePeriod, setTimePeriod] = useState("day");
  const [data] = useFetchData(apiService.countServiceDones, {
    service_id,
  });
  const handleTimePeriodChange = async (e: RadioChangeEvent) => {
    setTimePeriod(e.target.value);
  };

  useEffect(() => {
    if (Object.keys(data).length !== 0) {
      let formattedData: DateCount[] = [];
      switch (timePeriod) {
        case "week":
          formattedData = data.week_counts.map((item: ServiceDoneCount) => ({
            x: item.week_formatted ?? "",
            y: item.count,
          }));
          break;
        case "month":
          formattedData = data.month_counts.map((item: ServiceDoneCount) => ({
            x: item.month_formatted ?? "",
            y: item.count,
          }));
          break;
        default:
          formattedData = data.day_counts.map((item: ServiceDoneCount) => ({
            x: item.day_formatted ?? "",
            y: item.count,
          }));
          break;
      }

      const numberOfBars = isSmaller1024 ? 10 : 30;

      formattedData = formattedData.slice(-numberOfBars);

      setChartData(formattedData);
    }
  }, [data, timePeriod, isSmaller1024]);

  const chartState = {
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        labels: {
          hideOverlappingLabels: false,
          rotateAlways: true,
        },
      },
    },
    series: [
      {
        name: t("Copied objects"),
        data: chartData,
      },
    ],
  };

  return (
    <div>
      <Radio.Group value={timePeriod} onChange={handleTimePeriodChange}>
        <Radio.Button value="day">{t("Day")}</Radio.Button>
        <Radio.Button value="week">{t("Week")}</Radio.Button>
        <Radio.Button value="month">{t("Month")}</Radio.Button>
      </Radio.Group>
      <Chart
        options={chartState.options}
        series={chartState.series}
        type="bar"
        height={300}
      ></Chart>
    </div>
  );
};

export default ServiceDonesGraph;
