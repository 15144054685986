import React, { useState } from "react";
import { Button, Checkbox, Flex, Form, Input, message, Typography } from "antd";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import * as apiService from "../services/apiServices";
import { useTranslation } from "react-i18next";
import { ModifyButton, SubmitButton } from "../components/styledComponents";
import { displayError } from "../Helpers";

const { Text, Title } = Typography;

const NormalLoginForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onFinish = async (values: any) => {
    try {
      await apiService.login(values.email, values.password, values.remember);
      const customers = await apiService.getCustomers();
      localStorage.setItem("customer", JSON.stringify(customers[0]));
      navigate("/services");
    } catch (error) {
      console.error(error);
      message.error(
        t(displayError(error, "Unknown error with authentication")),
      );
    }
  };
  const [visible, setVisible] = useState(false);

  return (
    <Form
      name="normal_login"
      size="large"
      className="loginForm"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
    >
      <Title>Login</Title>
      <Text type="secondary">{t("Welcome, please login")}</Text>
      <Form.Item
        style={{ marginTop: 30 }}
        name="email"
        rules={[
          {
            required: true,
            message: t("Please enter your email"),
          },
        ]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          autoComplete="email"
          placeholder="Email"
          type="email"
          style={{ height: "50px" }}
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: t("Please enter your password"),
          },
        ]}
      >
        <Input
          style={{ height: "50px" }}
          prefix={<LockOutlined className="site-form-item-icon" />}
          type={visible ? "text" : "password"}
          placeholder={t("Password")}
          suffix={
            <Button type="text" onClick={() => setVisible(!visible)}>
              {visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
            </Button>
          }
        />
      </Form.Item>

      <Form.Item>
        <Flex justify="space-between">
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>{t("Remember me (30 days)")}</Checkbox>
          </Form.Item>

          <a className="login-form-forgot" href="/password-reset">
            {t("Forgot password")}
          </a>
        </Flex>
      </Form.Item>

      <Form.Item>
        <SubmitButton>{t("Log in")}</SubmitButton>
      </Form.Item>
      <Form.Item>
        <a href="register">
          <ModifyButton>{t("Create new account")}</ModifyButton>
        </a>
      </Form.Item>
    </Form>
  );
};

export default NormalLoginForm;
